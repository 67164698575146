import styled from 'styled-components';

const SlotBodyHeight = 'calc(100%)';
const SlotDetailsWrpGap = 0;

export const SlotWrp = styled.div`
  position: relative;
  margin: 0rem 0rem 2rem 0rem;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ dashboard }) => dashboard && '0.75rem'};

  .y.axis > .domain {
    display: none;
  }

  .y.axis > .tick > .y-axis-line {
    display: block;
  }

  .y.axis > .tick > text {
    fill: ${({ theme }) => theme?.light?.graphColors?.coolGray60};
    font-size: 0.6875rem;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fontFamily};
    /* text-transform: capitalize; */
  }
  .x.axis > .tick > text {
    fill: ${({ theme }) => theme?.light?.graphColors?.coolGray60};
    font-size: 0.6875rem;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fontFamily};
    /* text-transform: capitalize; */
  }
  .x.axis > .domain {
    stroke: ${({ theme }) => theme.graphColors.coolGray30};
  }
`;
export const FullSlot = styled.div`
  width: 100%;
  height: ${({ height }) => height ?? '30rem'};
  border-radius: ${({ theme }) => theme.primaryBorderRadius};
  padding: 1rem 1.5rem;
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 1rem;
  border: 2px solid ${({ selected }) => (selected ? 'blue' : 'none')};
  z-index: ${({ selected }) => (selected ? 1 : null)};
`;

export const HalfSlot = styled.div`
  height: 27.55rem;
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.primaryBorderRadius};
  padding: 1.25rem 1.25rem;
  width: calc(50% - 0.375rem);
  /* cursor: pointer; */
  border: 2px solid ${({ selected }) => (selected ? 'blue' : 'none')};
  /* z-index: ; */
`;
export const SlotDetailsMainWrp = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;
export const IconBox = styled.div`
  cursor: auto;
  position: absolute;
  /* width: 5.75rem; */
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: start;
  top: 0rem;
  right: 0;
  gap: 0.5rem;
`;
export const Iconwpr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ width = '2.25rem', height = '2.25rem' }) => `
  height: ${height};
  width: ${width};
  `}
  cursor: pointer;
  border-radius: 0.25rem;
  position: relative;
`;

export const SlotOverviewWrapper = styled.div`
  width: fit-content;
`;

export const SubTitleSmallWrp = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray60};
`;

export const TopThemeToggleWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.875rem;
  margin-left: 0rem;
`;

export const SlotDetailsWrp = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ hideGap }) => (hideGap ? '0rem' : '3rem')};
`;
export const SlotHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 1rem;
  justify-content: space-between;
`;

export const SlotHeaderLeft = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`;
export const SlotTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const SlotSubTitle = styled.div`
  font-size: 0.8rem;
  color: #585858;
`;
export const SlotBody = styled.div`
  position: relative;
  height: ${SlotBodyHeight};
  width: 100%;
  &.legend,
  &.commentary {
    height: calc(${SlotBodyHeight} - 2rem);
  }

  .selected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }

  .unselected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }

  .hover-selected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }
  .hover-unselected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }
  .word-cloud-text {
    font-weight: 700;
  }
`;
export const SlotBodyHeader = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const SlotBodyMain = styled.div`
  width: 100%;
  height: 100%;
  .selected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }

  .unselected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }

  .hover-selected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }
  .hover-unselected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }
`;
export const SlotBodyHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const SlotBodyHeaderRight = styled.div``;
export const SlotBodyHeaderTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;
export const SlotBodyHeaderSubTitle = styled.div`
  color: ${({ theme }) => theme.text};
  text-transform: uppercase;
  font-size: 0.78rem;
  font-weight: 500;
`;

export const LegendCon = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
export const LegendBox = styled.div`
  height: 0.65rem;
  width: 0.65rem;
  border-radius: 10rem;
  background-color: ${({ legendColor = '' }) => legendColor};
`;
export const LegendLabel = styled.div`
  font-size: 11px;
  font-weight: 500;
  text-transform: capitalize;
  color: #585858;
  ${({ textAlign = true }) =>
    textAlign &&
    `max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `}
`;
export const SlotFooter = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;

export const CampNoDatatxtWrp = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;

export const StageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StageItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StageDot = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: ${(props) => props.color};
  margin-right: 6px;
`;

export const Divider = styled.span`
  margin: 0 6px;
  color: #ccc;
  font-size: 11px;
`;

export const Label = styled.span`
  color: #697077;
  font-size: 11px;
  font-weight: 500;
`;

export const EngagementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const EngagementLabel = styled.span`
  color: #697077;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
`;

export const GradientBar = styled.div`
  height: 24px;
  width: 400px;
  border-radius: 2px;
  background: linear-gradient(90deg, #e8dbff 0%, #491d8b 100%);
  margin: 0 16px;
`;

export const TimeLineLabel = styled.div`
  color: ${(props) => props.color};
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
`;

export const SlotHeaderRight = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const SlotLeftWrp = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const LegendSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: ${({ alignItem }) => (alignItem ? 'flex-start' : 'flex-end')};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  margin-top: ${({ alignItem }) => (alignItem ? '0' : '3.5rem')};
`;
