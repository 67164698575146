import {
  coolGrayColorGradients,
  greenColorGradients,
  redColorGradients,
} from '../../constants/graph-colors';

export const topAdvocatesChartMapData = {
  title: 'Top Advocates',
  subTitle: '',
  component: 'advocates',
  graphType: '',
  data: {
    title: 'Top Advocates',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Influencer', value: 'name' },
      { label: 'Channel', value: 'media_type' },
      { label: 'No. of posts (+ve)', value: 'total_posts' },
      { label: 'Total Engagement', value: 'total_engagements', hide: true },
      { label: 'Reach', value: 'total_reach' },
    ],
    data: [],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const topDetractorsChartMapData = {
  title: 'Top Detractors',
  subTitle: '',
  component: 'detractors',
  graphType: '',
  data: {
    title: 'Top Detractors',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Influencer', value: 'name' },
      { label: 'Channel', value: 'media_type' },
      { label: 'No. of posts (-ve)', value: 'total_posts' },
      { label: 'Total Engagement', value: 'total_engagements', hide: true },
      { label: 'Reach', value: 'total_reach' },
    ],
    data: [],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const contentType = {
  title: 'Content Type',
  subTitle: 'Total articles',
  component: 'advanced_campaign_content_type',
  // graphType: 'column',
  graphType: 'top_author_bar',
  data: {
    title: 'Content Type',
    subtitle: 'Total Articles',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [
      {
        label: 'Author 1',
        value: 10000,
        thresholdValue: 10000,
        color: '#675EF2',
      },
      {
        label: 'Author 2',
        value: 8746,
        thresholdValue: 8500,
        color: '#F54A80',
      },
      {
        label: 'Author 3',
        value: 7984,
        thresholdValue: 7900,
        color: '#00C4DC',
      },
      {
        label: 'Author 4',
        value: 6000,
        thresholdValue: 6000,
        color: '#FCAF41',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const sentimeOverTime = {
  title: 'Sentiment Over Time',
  subTitle: '',
  component: 'advanced_campaign_sentiment_over_time',
  graphType: 'stacked_line',
  dataType: ['2d'],
  data: {
    title: '',
    subtitle: '',
    summary: {
      subtext: 'Group Stacked column',
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: 'Compliance Categories',
    },
    data: [],
    labels: [
      {
        label: 'Positive',
        value: 'POS',
        color: greenColorGradients?.green50,
      },
      {
        label: 'Negative',
        value: 'NEG',
        color: redColorGradients?.red50,
      },
      {
        label: 'Neutral',
        value: 'NEU',
        color: coolGrayColorGradients?.coolGray40,
      },
    ],
    info: [],
  },
};

export const resultOveTime = {
  title: 'Results Over Time ',
  subTitle: '',
  component: 'advanced_result_over_time',
  graphType: 'line',
  dataType: ['1d'],
  data: {
    title: 'Grouped Stacked',
    subtitle: '2020-04-17',
    summary: {
      subtext: 'Group Stacked column',
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: 'Compliance Categories',
    },
    data: [
      {
        label: 'Jan',
        gucci: '100000',
        color: '#7EBDC2',
      },
      {
        label: 'Feb',
        gucci: '700000',
        color: '#7EBDC2',
      },
      {
        label: 'Mar',
        gucci: '1400000',
        color: '#7EBDC2',
      },
      {
        label: 'Apr',
        gucci: '2500000',
        color: '#7EBDC2',
      },
      {
        label: 'May',
        gucci: '1500000',
        color: '#7EBDC2',
      },
      {
        label: 'Jun',
        gucci: '2000000',
        color: '#7EBDC2',
      },
      {
        label: 'Jul',
        gucci: '1500000',
        color: '#7EBDC2',
      },
      {
        label: 'Aug',
        gucci: '2000000',
        color: '#7EBDC2',
      },
      {
        label: 'Sep',
        gucci: '1400000',
        color: '#7EBDC2',
      },
      {
        label: 'Oct',
        gucci: '2200000',
        color: '#7EBDC2',
      },
      {
        label: 'Nov',
        gucci: '1500000',
        color: '#7EBDC2',
      },
      {
        label: 'Dec',
        gucci: '1800000',
        color: '#7EBDC2',
      },
    ],
    predictiveData: [
      {
        label: 'Jan',
        gucci: '100000',
        color: '#7EBDC2',
      },
      {
        label: 'Feb',
        gucci: '700000',
        color: '#7EBDC2',
      },
      {
        label: 'Mar',
        gucci: '1400000',
        color: '#7EBDC2',
      },
    ],
    labels: [
      {
        label: 'Gucci',
        value: 'gucci',
        colorOpacity: 0.1,
      },
    ],
    info: [],
  },
};

export const topThemeChartMapData = {
  title: 'Top Themes',
  subTitle: '',
  component: 'concentric_pie_chart',
  graphType: 'concentric_pie_chart',
  data: {
    title: 'Top Themes',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.9 Million',
      subLabel: 'Total',
    },
    data: [
      {
        label: 'Betting Week',
        value: 91,
        thresholdValue: 95,
        color: '#614CFF',
      },
      {
        label: 'Airica Steed',
        value: 92,
        thresholdValue: 20,
        color: '#8A74FF',
      },
      {
        label: 'Nfl Betting',
        value: 93,
        thresholdValue: 59,
        color: '#D16BFD',
      },
      {
        label: 'Surprising Launches',
        value: 94,
        thresholdValue: 27,
        color: '#9955F4',
      },
      {
        label: 'Boston Globe',
        value: 95,
        thresholdValue: 16,
        color: '#4337A0',
      },
      {
        label: 'Jaguars-titans Report',
        value: 96,
        thresholdValue: 54,
        color: '#85ECE9',
      },
      {
        label: 'Metrohealth Ceo',
        value: 97,
        thresholdValue: 56,
        color: '#32CBC8',
      },
      {
        label: 'Steed Talks',
        value: 98,
        thresholdValue: 47,
        color: '#35C684',
      },
      {
        label: 'Underdogs Multiple',
        value: 99,
        thresholdValue: 93,
        color: '#FA8750',
      },
      {
        label: 'Multiple Ministries',
        value: 100,
        thresholdValue: 62,
        color: '#EFB73E',
      },
      {
        label: 'Mavericks Finally',
        value: 101,
        thresholdValue: 11,
        color: '#FFE566',
      },
      {
        label: 'Damar Hamlin',
        value: 102,
        thresholdValue: 35,
        color: '#EB4A84',
      },
      {
        label: 'Hamlin Update',
        value: 95,
        thresholdValue: 44,
        color: '#6D5E7B',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const totalOverviewSocialDetailsMap = [
  {
    title: 'Total Articles',
    data: 0,
    change: 0,
    isIncreased: false,
  },
  {
    title: 'Total Reach',
    data: '-',
    change: '0',
    isIncreased: false,
  },
  {
    title: 'Total AVE',
    data: '-',
    change: '0',
    isIncreased: false,
  },
];
