/* eslint-disable indent */
import * as d3 from 'd3';
import { initialValues } from './graphConst';

const ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'B' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'K' },
];

export const formatNumber = (n) => {
  if (n === 0 || n < 1000 || Number.isNaN(n)) {
    return n?.toString();
  }

  for (let i = 0; i < ranges.length; i++) {
    if (n < 0) {
      return '-' + formatNumber(-n);
    }
    if (n >= ranges[i].divider) {
      let value = n / ranges[i].divider;

      // Round to 2 decimal places
      value = Math.round(value * 100) / 100;

      // Convert to string, removing trailing zeros and decimal point if necessary
      let valueString = value.toFixed(2).replace(/\.?0+$/, '');

      // If the value is exactly 1, don't show decimal places
      if (value === 1) {
        valueString = '1';
      }

      return valueString + ranges[i].suffix;
    }
  }

  return n?.toString();
};

export const getFormatDate = (dateString, config) => {
  const containsAmPm = dateString?.includes('AM') || dateString?.includes('PM');
  // const isTwelveAm = dateString?.includes('12:00 AM');
  const isDateLong = dateString?.length > 10;
  if (containsAmPm) {
    return dateString?.slice(10);
  }
  if (isDateLong && !config?.labelStraight) {
    return dateString?.slice(0, 10) + '..';
  }
  return dateString;
};
export const getFormatString = (string) => {
  const isStringLong = string?.length > 7;

  if (isStringLong) {
    return string?.slice(0, 7) + '..';
  }
  return string;
};

export const getTickInterval = (numOfTicks, config) => {
  const width = config.width || config.graphAreaW || window.innerWidth;
  const differentiator = config?.prImpact ? 74 : 64;
  // Define a target number of ticks to display based on the screen width
  const targetTicksPerWidth = Math.max(Math.floor(width / differentiator), 1);
  // Calculate the dynamic interval
  const dynamicInterval = Math.ceil(numOfTicks / targetTicksPerWidth);
  // To ensure the interval does not exceed available ticks
  return Math.min(dynamicInterval, numOfTicks);
};

const includeLabel = (item) => {
  if (item?.toLowerCase() === 'x (twitter)') {
    return 'twitter';
  }
  return item?.toLowerCase();
};

export const xAxis = function xAxis() {
  let config = {
    ...initialValues,
    fontFamily: 'inherit',
    xAxisType: 'text',
    enableGridXLine: false,
    gridLineXStroke: '#D0D3E5',
    totalCount: null,
  };

  const t = d3
    .transition()
    .delay(function (d, i) {
      return i * 3;
    })
    .duration(config.duration);

  function graph(selected) {
    selected.each((data) => {
      if (!config.hideXAxis) {
        const numOfTicks = config?.heatMapInvoke
          ? data[0]?.[0]?.length
          : data[0].length;
        const xAxis = selected.selectAll('.x').data([data]);
        // Conditionally rendering total counts for Online and Print
        if (config.totalCount && config.breakDownByMedia) {
          // Define labels and values for Online and Print
          const breakDownLabels = [
            'Online',
            // 'Print',
            'Forums',
            'Reviews',
            'Blogs',
            // 'X (Twitter)',
            // 'Reddit',
            // 'YouTube',
          ];

          const labels = config?.mediaType?.includes('all')
            ? breakDownLabels
            : breakDownLabels.filter((item) =>
                config.mediaType
                  .map((type) => type.toLowerCase())
                  .includes(includeLabel(item))
              );
          const values = config.totalCount; // Example values, replace with actual logic to compute values

          selected
            .selectAll('.breakdown-label-group')
            .data(labels)
            .join(
              (enter) => {
                const labelGroup = enter
                  .append('g')
                  .attr('class', 'breakdown-label-group');

                labelGroup
                  .append('text')
                  .attr('class', 'breakdown-label')
                  .attr('x', (d, i) =>
                    // config.xScale(d) +
                    // config.graphAreaH +
                    // (config.xScale.bandwidth() / 1.65) * i +
                    // 1
                    config.xScale(d) + i === 0
                      ? config.xScale.bandwidth()
                      : config.xScale.bandwidth() +
                        (config?.graphAreaW / config?.numCategories) * i
                  )
                  .attr('y', config.graphAreaH + 20) // Adjust y axis position as needed
                  .attr('text-anchor', 'middle')
                  .style('fill', '#6B727C')
                  .style('font-size', '11px')
                  .style('font-weight', 500)
                  .text((d) => d);

                labelGroup
                  .append('text')
                  .attr('class', 'breakdown-value')
                  .attr('x', (d, i) =>
                    // config.xScale(d) +
                    // config.graphAreaH +
                    // (config.xScale.bandwidth() / 1.65) * i +
                    // 1
                    config.xScale(d) + i === 0
                      ? config.xScale.bandwidth()
                      : config.xScale.bandwidth() +
                        (config?.graphAreaW / config?.numCategories) * i
                  )
                  .attr('y', config.graphAreaH + 37) // Adjust y position as needed, below the text
                  .attr('text-anchor', 'middle')
                  .style('fill', '#000')
                  .style('font-size', '12px')
                  .style('font-weight', 600)
                  .text((d, i) => formatNumber(values[i]));
              },
              (update) => {
                update
                  .select('.breakdown-label')
                  .attr('x', (d, i) =>
                    // config.xScale(d) +
                    // config.graphAreaH +
                    // (config.xScale.bandwidth() / 1.65) * i +
                    // 1
                    config.xScale(d) + i === 0
                      ? config.xScale.bandwidth()
                      : config.xScale.bandwidth() +
                        (config?.graphAreaW / config?.numCategories) * i
                  )
                  .text((d) => d);
                update
                  .select('.breakdown-value')
                  .attr('x', (d, i) =>
                    // config.xScale(d) +
                    // config.graphAreaH +
                    // (config.xScale.bandwidth() / 1.65) * i +
                    // 1
                    config.xScale(d) + i === 0
                      ? config.xScale.bandwidth()
                      : config.xScale.bandwidth() +
                        (config?.graphAreaW / config?.numCategories) * i
                  )
                  .text((d, i) => formatNumber(values[i]));
              },
              (exit) => {
                exit.remove();
              }
            );
        }
        if (config?.heatMapInvoke && config?.weeklyDataEnable) {
          selected
            .selectAll('.weekly-label-group')
            .data((d) => {
              return d[0][0]; // Ensure you're binding the correct data array
            })
            .join(
              // Enter Block
              (enter) => {
                const labelGroup = enter
                  .append('g')
                  .attr('class', 'weekly-label-group');

                labelGroup
                  .append('text')
                  .attr('class', 'weekly-label')
                  .attr(
                    'x',
                    (d) =>
                      config.xScale(d?.xLabel) + config.xScale.bandwidth() / 2 // Correct X position (centered)
                  )
                  .attr('y', config.graphAreaH + 20) // Adjust Y position to ensure visibility
                  .attr('text-anchor', 'middle') // Ensure text is center aligned
                  .style('fill', '#6B727C')
                  .style('font-size', '11px')
                  .style('font-weight', 500)
                  .selectAll('tspan') // Append tspans for multiline text
                  .data((d, i) => {
                    if (typeof d.xLabel !== 'string') {
                      console.error('Invalid label format:', d);
                      return ['Invalid', 'Label'];
                    }

                    const splitLabel = d.xLabel.split(' '); // Split based on spaces
                    const skipper = Math.ceil(numOfTicks / config.gridXTicks);

                    return i % skipper === 0
                      ? [splitLabel[0], splitLabel.slice(1).join(' ')]
                      : '';
                  })
                  .join('tspan')
                  .attr('x', function () {
                    // Align tspans horizontally to the center of the parent text
                    return d3.select(this.parentNode).attr('x');
                  })
                  .attr('dy', (d, i) => (i === 0 ? 0 : 13))
                  .attr('dx', '3.5em')
                  .text((d) => d);
              },

              // Update Block
              (update) => {
                update
                  .select('.weekly-label')
                  .attr(
                    'x',
                    (d) =>
                      config.xScale(d?.xLabel) + config.xScale.bandwidth() / 2
                  )
                  .attr('y', config.graphAreaH + 20)
                  .attr('text-anchor', 'middle')
                  .style('fill', '#6B727C')
                  .style('font-size', '11px')
                  .style('font-weight', 500)
                  .selectAll('tspan')
                  .data((d, i) => {
                    if (typeof d.xLabel !== 'string') {
                      console.error('Invalid label format:', d);
                      return ['Invalid', 'Label'];
                    }

                    const splitLabel = d.xLabel.split(' '); // Split based on spaces
                    const skipper = Math.ceil(numOfTicks / config.gridXTicks);

                    return i % skipper === 0
                      ? [splitLabel[0], splitLabel.slice(1).join(' ')]
                      : '';
                  })
                  .join('tspan')
                  .attr('x', function () {
                    return d3.select(this.parentNode).attr('x');
                  })
                  .attr('dy', (d, i) => (i === 0 ? 0 : 13)) // Ensure consistent vertical spacing between lines
                  .attr('dx', '3.5em')
                  .text((d) => d);
              },

              // Exit Block
              (exit) => exit.remove()
            );
        }

        const BrowserText = (function () {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          /**
           * Measures the rendered width of arbitrary text given the font size and font face
           * @param {string} text The text to measure
           * @param {number} fontSize The font size in pixels
           * @param {string} fontFace The font face ("Arial", "Helvetica", etc.)
           * @returns {number} The width of the text
           **/
          function getWidth(text, fontSize, fontFace) {
            context.font = fontSize + 'px ' + fontFace;
            return context.measureText(text).width;
          }

          return {
            getWidth,
          };
        })();

        const xAxist = d3.axisBottom(config.xScale);

        if (config.gridXTicks) {
          xAxist.ticks(config.gridXTicks).tickSizeOuter(0);
        }
        if (config.gridXTicks && config.xAxisROTStartEndTicks) {
          xAxist.ticks(config.gridXTicks).tickSizeOuter(0);
        }
        if (config.xAxisType === 'text') {
          xAxist.tickFormat((d, i) => {
            if (config.singleLineWrp) {
              const xWidth =
                config.xScale.bandwidth() -
                config.gutterSpace / data.length -
                (config.groupgutterSpace || 0);
              let maxWidth =
                config.gutterSpace +
                (xWidth / data.length - (config.groupgutterSpace || 0)) * 2;
              maxWidth = maxWidth * (config.singleLineWrpWidth || 0.5);
              if (config.statusCircleRadius) {
                maxWidth = maxWidth - config.statusCircleRadius * 2;
              }
              if (
                config.component === 'coverage_by_source' ||
                config.component === 'coverage_by_journalist' ||
                config.dashboardType === 'congruence'
              ) {
                const charLen =
                  BrowserText.getWidth(d, config.fontSize + 5 || 16) / d.length;
                const maxChar = Math.floor(maxWidth / charLen);

                if (d.length < maxChar) {
                  return d;
                }

                const skipper = Math.ceil(numOfTicks / config.gridXTicks);

                const bandWidth =
                  config.xScale.bandwidth() || config.xScale.step();
                const skippedMaxChar = Math.floor(
                  (bandWidth * (skipper > 0 ? skipper - 0.25 : 0)) / charLen
                );

                return config.gridXTicks
                  ? i % skipper === 0
                    ? d.length < skippedMaxChar
                      ? d
                      : `${d.slice(0, skippedMaxChar)}..`
                    : ''
                  : `${d.slice(0, maxChar)}..`;
                // return d;
              }

              if (config?.isHorizontalBar) {
                const interval = getTickInterval(numOfTicks, config);
                if (
                  config?.dashboardType !== 'overview'
                    ? i % interval === 0
                    : i % interval === 0
                ) {
                  return getFormatString(d);
                } else {
                  return '';
                }
              }
              // Show all labels logic
              if (config?.showAllLabels) {
                const interval = getTickInterval(numOfTicks, config);
                if (
                  config?.dashboardType !== 'overview'
                    ? i % interval === 0
                    : i % interval === 0
                ) {
                  return getFormatDate(d, config);
                } else {
                  return '';
                }
              } else if (
                BrowserText.getWidth(d, config.fontSize || 16) > xWidth
              ) {
                const charLen =
                  BrowserText.getWidth(d, config.fontSize || 16) / d.length;

                const maxChar = Math.floor(maxWidth / charLen);

                if (d.length < maxChar) {
                  return d;
                }

                const skipper = Math.ceil(numOfTicks / config.gridXTicks);

                const bandWidth =
                  config.xScale.bandwidth() || config.xScale.step();
                const skippedMaxChar = Math.floor(
                  (bandWidth * (skipper > 0 ? skipper - 0.25 : 0)) / charLen
                );

                return config.gridXTicks
                  ? i % skipper === 0
                    ? d.length < skippedMaxChar
                      ? d
                      : `${d.slice(0, skippedMaxChar)}..`
                    : ''
                  : `${d.slice(0, maxChar)}..`;
              }
              return d;
            }
            // if (typeof d === 'string' && d.length - 1 > 10) {
            //   return i % Math.ceil(numOfTicks / 6) === 0
            //     ? `${d.slice(0, 7)}..`
            //     : '';
            // }
            // if showAllLabels is true we are displaying all the labels

            if (
              config?.showAllLabels &&
              (config.component !== 'coverage_by_source' ||
                config.component !== 'coverage_by_journalist')
            ) {
              const interval = getTickInterval(numOfTicks, config);
              if (i % interval === 0) {
                return getFormatDate(d, config);
              } else {
                return '';
              }
            } else if (
              config.gridXTicks ||
              (config.prImpact && config.dashboardType)
            ) {
              return i % Math.ceil(numOfTicks / config.gridXTicks) === 0
                ? d.length > 10
                  ? `${d.slice(0, 10)}..`
                  : d
                : '';
            }
            return d;
          });
        }

        // else {
        //   xAxist.tickFormat((d, i) => {
        //     return config.xAxisTicksFormat ? formatNumber(d) : d;
        //   });
        // }

        const xAxisn = d3.axisBottom(config.xScaleN);

        if (config.gridXTicks || config.authorDataLength) {
          xAxisn.ticks(config.gridXTicks).tickSizeOuter(0);
        }

        if (config.xAxisType === 'number') {
          xAxisn.tickFormat((d, i) => {
            if (config.valueInPercent) {
              if (d < 10) {
                if (Number.isInteger(d)) {
                  return config.campaginTranslate && d === 0 ? '' : `${d}%`;
                } else {
                  return '';
                }
              }
              return `${config.xAxisTicksFormat ? formatNumber(d) : d}%`;
            } else {
              if (d < 10) {
                if (Number.isInteger(d)) {
                  return d;
                } else {
                  return '';
                }
              }
              return config.xAxisTicksFormat ? formatNumber(d) : d;
            }
          });
        }

        const heightDifference = () => {
          if (
            config.authorDataLength !== undefined &&
            config.authorDataLength > 50 &&
            config.authorDataLength <= 80
          ) {
            return config.graphAreaH + config.authorDataLength - 15;
          } else if (
            config.authorDataLength <= 50 &&
            config.authorDataLength > 30
          ) {
            return config.graphAreaH + config.authorDataLength + 10;
          } else if (config.authorDataLength <= 30) {
            return config.graphAreaH + config.authorDataLength + 40;
          } else if (
            config.articleSentiment ||
            (config?.authorDataLength > 80 &&
              (config?.dashboardType === 'authorimpact' ||
                config?.dashboardType === 'overview'))
          ) {
            return config.graphAreaH + 20;
          }
        };

        const clearArea =
          config.width < config.height ? config.width : config.height;
        !config.breakDownByMedia &&
          !config?.weeklyDataEnable &&
          xAxis
            .enter()
            .append('g')
            .attr('class', 'x axis')
            .merge(xAxis)
            .attr(
              'transform',
              `translate(${
                (config?.heatMapInvoke ? 20 : config.yLabelAlignment) +
                config.padding.left
              },${
                heightDifference()
                  ? heightDifference()
                  : config.negValueInvolved
                  ? config.graphAreaH
                  : config.yScale(0) || config.graphAreaH
              })`
            )
            .transition()
            .duration(config.duration)
            .call(config.xAxisType === 'text' ? xAxist : xAxisn)
            .selectAll('text')
            .attr('font-family', config.fontFamily)
            .attr('font-weight', config.fontWeight)
            .attr('color', config.fontColor)
            .style('text-anchor', (d, i) => {
              if (config.xAxisType === 'text' && config.isCongruence) {
                return 'center';
              } else {
                return config.xAxisType === 'text' ?? 'center';
              }
            })
            // .style('text-transform', 'uppercase')
            // .attr('dx', config.xAxisType === 'text' ? '-1em' : '-10')
            .attr(
              'dx',
              config?.heatMapInvoke
                ? '3em'
                : config?.prImpact
                ? '1em'
                : config.xAxisType === 'text'
                ? config.isCongruence
                  ? '0em'
                  : '0em'
                : '0em'
            )
            .attr(
              'dy',
              // config.labelStraight && config.xAxisType === 'text'
              //   ? '1em'
              //   : config.xAxisType === 'text'
              //   ? '-.5em'
              //   : '0'
              '1em'
            )
            .attr(
              'transform',
              // config.xAxisType === 'text' && !config.labelStraight
              //   ? 'rotate(-60)'
              //   : 'rotate(0)'
              // config.prImpact && config.dashboardType
              //   ? 'rotate(-60)'
              'rotate(0)'
            )
            .attr('y', config.xAxisLabelBreatingSpace)
            .attr(
              'font-size',
              config.graphXYLabelFontSize ||
                config.gridXYLabelFontSize ||
                clearArea * 0.05
            );

        if (
          config.negValueInvolved ||
          config?.breakDownByMedia ||
          config.heatMapInvoke
        ) {
          selected
            .selectAll('.x.axis path, .x.axis line')
            .style('display', 'none');
        }

        selected
          .selectAll('.x')
          .selectAll('.tick')
          .selectAll('title')
          .data((d) => [d])
          .join((enter) => {
            enter.append('title').text((d) => d);
          })
          .text((d) => d);
        if (config.statusCircle) {
          selected
            .selectAll('.x')
            .selectAll('.tick')
            .selectAll('circle')
            .data((d) => {
              return [d];
            })
            .join(
              (enter) => {
                enter
                  .append('circle')
                  .attr('r', config.statusCircleRadius || 5)

                  .attr(
                    'cy',
                    config.xAxisLabelBreatingSpace +
                      (config.statusCircleRadius || 0)
                  )
                  .attr('fill', (d, i) => {
                    return data.length > 0
                      ? data[0].filter((ele) => ele.label === d)[0]
                          ?.statusCircleColor || 'red'
                      : 'red';
                  })
                  .attr('cx', 0)
                  .transition(t)
                  .attr('cx', (d) => {
                    const xWidth =
                      config.xScale.bandwidth() -
                      config.gutterSpace / data.length -
                      (config.groupgutterSpace || 0);
                    let maxWidth =
                      config.gutterSpace +
                      (xWidth / data.length - (config.groupgutterSpace || 0)) *
                        2;
                    maxWidth = maxWidth * (config.singleLineWrpWidth || 0.5);
                    if (config.statusCircleRadius) {
                      maxWidth = maxWidth - config.statusCircleRadius * 2;
                    }
                    let finalText = d;
                    if (
                      BrowserText.getWidth(d, config.fontSize || 16) > xWidth
                    ) {
                      const charLen =
                        BrowserText.getWidth(d, config.fontSize || 16) /
                        d.length;

                      const maxChar = Math.floor(maxWidth / charLen);

                      if (d.length < maxChar) {
                        finalText = d;
                      }
                      finalText = `${d.slice(0, maxChar)}..`;
                    }
                    // finalText = d;

                    const wordLength = BrowserText.getWidth(
                      finalText,
                      config.fontSize || 16
                    );
                    return (
                      -1 *
                      (wordLength / (config.statusCircleXSpacing || 1.8) +
                        config.statusCircleRadius * 2)
                    );
                  });
              },
              (update) => update,
              (exit) => {
                exit.remove();
              }
            )
            .transition(t)
            .attr('fill', (d, i) => {
              return data.length > 0
                ? data[0].filter((ele) => ele.label === d)[0]
                    ?.statusCircleColor || 'red'
                : 'red';
            })
            .attr('cx', (d) => {
              const xWidth =
                config.xScale.bandwidth() -
                config.gutterSpace / data.length -
                (config.groupgutterSpace || 0);
              let maxWidth =
                config.gutterSpace +
                (xWidth / data.length - (config.groupgutterSpace || 0)) * 2;
              maxWidth = maxWidth * (config.singleLineWrpWidth || 0.5);
              if (config.statusCircleRadius) {
                maxWidth = maxWidth - config.statusCircleRadius * 2;
              }
              let finalText = d;
              if (BrowserText.getWidth(d, config.fontSize || 16) > xWidth) {
                const charLen =
                  BrowserText.getWidth(d, config.fontSize || 16) / d.length;

                const maxChar = Math.floor(maxWidth / charLen);

                if (d.length < maxChar) {
                  finalText = d;
                }
                finalText = `${d.slice(0, maxChar)}..`;
              }
              // finalText = d;

              const wordLength = BrowserText.getWidth(
                finalText,
                config.fontSize || 16
              );
              return (
                -1 *
                (wordLength / (config.statusCircleXSpacing || 1.8) +
                  config.statusCircleRadius * 2)
              );
            });
        }
      }

      if (config.enableGridXLine) {
        selected
          .selectAll('.x')
          .selectAll('.tick')
          .selectAll('.x-axis-line')
          .data((d) => [d])
          .join(
            (enter) => {
              enter
                .append('line')
                .attr('class', 'x-axis-line')
                .attr('stroke', config.gridLineXStroke)
                // .attr('stroke-width', config.gridLineStrokeWidth)
                .attr('stroke-width', 0)
                .attr('y2', 0)
                .attr(
                  'y1',
                  -1 * (config.graphAreaW - config.xAxisLabelBreatingSpace)
                )
                .attr(
                  'stroke-dasharray',
                  !config?.negValueInvolved ? config.dasharray : 0
                );
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );
      }
      if (config.xLabelPos) {
        selected
          .selectAll('.x')
          .selectAll('.tick')
          .selectAll('text')
          .each(function (data) {
            const tick = d3.select(this);
            tick.attr('x', config.xScale.step() * config.xLabelPos);
            tick.attr('x', `${config.xScale.step() * config.xLabelPos}px`);
            // tick.attr("transform", (d) => {
            //   return `translate(${
            //     config.yLabelAlignment +
            //     config.padding.left +
            //     config.xScale.step() * config.xLabelPos
            //   },${config.graphAreaH})px`;
            // });
          });
      }
      if (config.tickClick) {
        const tickSelect = selected.selectAll('.tick');
        tickSelect
          .selectAll('text')
          .style('cursor', 'pointer')
          .on('click', (event, d) => {
            const clickedData = getClickedData(d, data);
            config.tickClick(event, clickedData, undefined);
          });
      }
    });
    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};

const getClickedData = (d, inData) => {
  const data = inData.length > 0 ? inData[0] : inData;
  const filteredData = data.filter((ele) => ele.label === d);
  return filteredData.length > 0 ? filteredData[0] : { label: d };
};

export const yAxis = function yAxis() {
  let config = {
    ...initialValues,
    yAxisType: 'text',
    enableGridYLine: false,
    // gridLineYStroke: '#D0D3E5',
    gridLineYStroke: '#DDE1E6',
    duration: 500,
  };

  function graph(selected) {
    selected.each((data) => {
      if (!config.hideYAxis) {
        // const yAxisGroup = selected.selectAll('.y').data([data])

        // const yAxisGroup = selected
        //   .selectAll('.y')
        //   .data([data])
        //   .join(
        //     (enter) => {
        //       enter.append('g').attr('class', 'y')
        //     },
        //     (update) => update,
        //     (exit) => {
        //       exit.remove()
        //     }
        //   )

        const yaxis = d3.axisLeft(config.yScale);
        if (config.gridYTicks) {
          yaxis.ticks(config.gridYTicks).tickSizeOuter(0);
        }
        if (config.yAxisType === 'text') {
          yaxis.tickFormat((d, i) => {
            if (typeof d === 'string' && d.length > (config.wrapLength || 8)) {
              return `${d.slice(0, config.wrapLength || 8)}..`;
            }

            return d;
          });
        } else if (config.yAxisType === '') {
          yaxis.tickFormat((d, i) => {
            if (typeof d === 'string' && d.length > (config.wrapLength || 8)) {
              return `${d.slice(0, config.wrapLength || 8)}..`;
            }

            return '';
          });
        } else {
          yaxis.tickFormat((d, i) => {
            if (config.yAxisTicksFormat) {
              if (formatNumber(d)?.length > 10) {
                return `${formatNumber(d).slice(0, 10)}..`;
              } else if (d <= 10) {
                if (Number.isInteger(d)) {
                  return d;
                } else {
                  return '';
                }
              } else {
                return formatNumber(d);
              }
            } else {
              return d;
            }
          });
        }
        // show percentage in y axis for Message Congruence
        if (config.yAxisType === 'percentage') {
          yaxis.tickFormat((d, i) => {
            return config.valueInPercent
              ? `${config.yAxisTicksFormat ? `${d}%` : `${d}`}`
              : config.yAxisTicksFormat
              ? formatNumber(d)
              : d;
          });
        }
        const clearArea =
          config.width < config.height ? config.width : config.height;

        selected
          .selectAll('.y')
          .data([data])
          .join(
            (enter) => {
              const yGroup = enter
                .append('g')
                .attr('class', 'y axis')
                .attr(
                  'transform',
                  `translate(${
                    config.campaginTranslate
                      ? config.width / 1.954
                      : config.yLabelAlignment +
                        (!config?.articleSentiment
                          ? config.padding.left
                          : -config.padding.right * 0.25)
                  },0)`
                );

              // Apply the y-axis without transition first
              yGroup.call(yaxis);

              // Conditionally add subtitles below each tick if config.isStackedOutlet is true
              if (config.isStackedOutlet) {
                yGroup
                  .selectAll('.tick')
                  .append('text')
                  .attr('class', 'y-subtitle')
                  .attr('text-anchor', 'middle')
                  .attr('dy', '1.5em') // Position it below each tick label
                  .attr('x', -22)
                  .text((d) => {
                    const filteredData = data[0].filter((x) => x.label === d);

                    const sum = Object.entries(filteredData[0]?.rawData)
                      .filter(([key]) => key !== 'label') // Exclude the label field
                      .reduce((acc, [, value]) => acc + value, 0);

                    return formatNumber(sum);
                  })
                  .style('font-family', config.fontFamily)
                  .style('fill', '#000');
              }

              // Add transition to yGroup afterward
              yGroup
                .transition()
                .duration(config.duration)
                .attr(
                  'font-size',
                  config.graphXYLabelFontSize ||
                    config.gridXYLabelFontSize ||
                    clearArea * 0.05
                )
                .attr('font-family', config.fontFamily)
                .attr('font-weight', config.fontWeight)
                .attr('color', config.fontColor);
            },
            (update) => {
              update
                .attr(
                  'transform',
                  `translate(${
                    config.campaginTranslate
                      ? config.width / 1.954
                      : config.yLabelAlignment +
                        (!config?.articleSentiment
                          ? config.padding.left
                          : -config.padding.right * 0.25)
                  },0)`
                )
                .call(yaxis);

              // Remove any existing subtitles before adding new ones
              update.selectAll('.tick .y-subtitle').remove();

              // Conditionally add subtitles if config.isStackedOutlet is true
              if (config.isStackedOutlet) {
                update
                  .selectAll('.tick')
                  .append('text')
                  .attr('class', 'y-subtitle')
                  .attr('text-anchor', 'middle')
                  .attr('dy', '1.5em') // Position it below each tick label
                  .attr('x', -22)
                  .text((d) => {
                    const filteredData = data[0].filter((x) => x.label === d);

                    const sum = Object.entries(filteredData[0]?.rawData)
                      .filter(([key]) => key !== 'label') // Exclude the label field
                      .reduce((acc, [, value]) => acc + value, 0);

                    return formatNumber(sum);
                  });
              }

              // Add transition after updating the axis
              update
                .transition()
                .duration(config.duration)
                .attr(
                  'font-size',
                  config.graphXYLabelFontSize ||
                    config.gridXYLabelFontSize ||
                    clearArea * 0.05
                )
                .attr('font-family', config.fontFamily)
                .attr('font-weight', config.fontWeight)
                .attr('color', config.fontColor);
            },
            (exit) => {
              exit.remove();
            }
          );

        selected
          .selectAll('.y')
          .selectAll('.tick')
          .selectAll('title')
          .data((d) => [d])
          .join(
            (enter) => {
              enter.append('title').text((d) => d);
            },
            (update) => update.text((d) => d)
          );
      }
      if (config.breakDownByMedia) {
        selected.selectAll('.y.axis path').style('display', 'block');
      }
      if (config.hideYAxisLine) {
        selected.selectAll('.y.axis path').style('display', 'none');
      }
      if (config.enableGridYLine) {
        selected
          .selectAll('.y')
          .selectAll('.tick')
          .selectAll('.y-axis-line')
          .data((d, i) => [{ label: d, index: i }])
          .join(
            (enter) => {
              enter
                .append('line')
                .attr('class', 'y-axis-line')
                .attr('stroke', config.gridLineYStroke)
                .attr('stroke-width', (d) => {
                  return config?.negValueInvolved
                    ? config.gridLineStrokeWidth
                    : d.label !== 0
                    ? config.gridLineStrokeWidth
                    : 0;
                })
                .attr('x1', config?.campaginTranslate ? '-840' : 0)
                .attr(
                  'x2',
                  config?.campaginTranslate
                    ? (config.graphAreaW *
                        (config.graphAreaWMultiplayer || 1)) /
                        2
                    : config.graphAreaW * (config.graphAreaWMultiplayer || 1)
                )
                // .attr('opacity', config?.campaginTranslate ? 0.4 : 1)
                .attr('stroke-dasharray', (d) => {
                  return (d?.label !== 0 && config.prImpact) ||
                    config.overTimeChart ||
                    config.sentimentOverTime
                    ? config.dasharray
                    : 0;
                });
            },
            (update) =>
              update
                .attr('stroke', config.gridLineYStroke)
                .attr('stroke-width', (d) => {
                  return config?.negValueInvolved
                    ? config.gridLineStrokeWidth
                    : d.label !== 0
                    ? config.gridLineStrokeWidth
                    : 0;
                })
                .attr('x1', config?.campaginTranslate ? '-840' : 0)
                .attr(
                  'x2',
                  config?.campaginTranslate
                    ? (config.graphAreaW *
                        (config.graphAreaWMultiplayer || 1)) /
                        2
                    : config.graphAreaW * (config.graphAreaWMultiplayer || 1)
                )
                // .attr('opacity', config?.campaginTranslate ? 0.4 : 1)
                .attr('stroke-dasharray', (d) => {
                  return (d?.label !== 0 && config.prImpact) ||
                    config.overTimeChart ||
                    config.sentimentOverTime
                    ? config.dasharray
                    : 0;
                }),
            // .attr('stroke-dasharray', 0),
            (exit) => {
              exit.remove();
            }
          );
      }
    });
  }
  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
