import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  Alert,
  Change,
  ChangeWrp,
  IndicatorIconWrp,
  IndicatorPercentageWrp,
  ResultContainer,
  ResultInFigContainer,
  ResultInNumber,
  ResultInNumberWrp,
  ResultIndicatorWpr,
  ResultTitle,
  //   ResultValueWpr,
  ResultsInFigureWrp,
  TooltipWrapper,
} from './index.sc';
import ArrowIncrease from '../../assets/icons/ArrowIncrease';
import ArrowDecrease from '../../assets/icons/ArrowDecrease';
import infoIcon from '../../assets/img/infoIcon.svg';

import { addCountPrefix } from '../../constants/utils';
import {
  ChartLabelExtraSmallWrp,
  ChartLabelLargeWrp,
  ChartLabelSmallWrp,
} from '../../constants/graph-colors/index.sc';
import { coolGrayColorGradients } from '../../constants/graph-colors';
import PortalTooltip from '../portal-tooltip';
import ReachTooltip from '../tooltip';
import Minus from '../../assets/icons/Minus';

const ResultsInFigure = ({ tileDetails, selectedMedia, eventCycle }) => {
  const traditional = ['Total Results', 'Traditional Reach', 'Total AVE'];
  const social = [
    'Social Mentions',
    'Social Reach',
    'Total Posts',
    // 'Total Engagement',
  ];

  const all = [
    'Total Results',
    'Social Mentions',
    'Traditional Reach',
    'Social Reach',
    'Total AVE',
    // 'Total Engagement',
  ];
  const mediaMappings = {
    social,
    all,
    traditional,
  };

  const getTileDetails = (tileDetails) => {
    const cards = mediaMappings[selectedMedia];

    return tileDetails
      ?.map((tile) => {
        return {
          ...tile,
          order: cards.findIndex((card) => card === tile?.title),
        };
      })
      .sort((a, b) => a.order - b.order);
  };
  return (
    <ResultsInFigureWrp style={{ opacity: eventCycle ? '0.7' : 1 }}>
      {getTileDetails(tileDetails)?.map((tile, id) => {
        const cards = mediaMappings[selectedMedia];

        if (!cards?.includes(tile?.title) && !eventCycle) {
          return null;
        }
        return (
          <Tile
            tile={tile}
            key={tile?.title}
            selectedMedia={selectedMedia}
            eventCycle={eventCycle}
          />
        );
      })}
    </ResultsInFigureWrp>
  );
};

export default ResultsInFigure;

ResultsInFigure.propTypes = {
  tileDetails: PropTypes.arrayOf(object),
  selectedMedia: PropTypes.string,
  eventCycle: PropTypes.bool,
};

const Tile = ({ tile, selectedMedia, eventCycle }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();

  const [showOverviewTooltip, setShowOverviewTooltip] = useState(false);
  const [overviewToolTipPos, setOverviewToolTipPos] = useState({
    left: 0,
    top: 0,
  });

  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [infoTooltipPos, setInfoTooltipPos] = useState({
    left: 0,
    top: 0,
  });
  const [overviewTooltipData, setOverviewTooltipData] = useState();

  const handleMouseEnter = (event) => {
    setShowTooltip(true);
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
    setTooltipData({
      label: tile?.title,
      value: tile?.data,
      isIncreased: tile.isIncreased,
      change: tile?.change,
      previousCount: tile?.previousCount,
    });
  };

  const handleMouseMove = (event) => {
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
  };

  const handleMouseLeave = (e) => {
    setToolTipPos({
      left: 0,
      top: 0,
    });
    setTooltipData();
    setShowTooltip(false);
  };

  const handleOverviewMouseEnter = (event) => {
    setShowOverviewTooltip(true);
    setOverviewToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
    setOverviewTooltipData({
      label: tile?.title,
      value: tile?.data,
      isIncreased: tile.isIncreased,
      change: tile?.change,
      previousCount: tile?.previousCount,
    });
  };

  const handleOverviewMouseMove = (event) => {
    setOverviewToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
  };

  const handleOverviewMouseLeave = (e) => {
    setOverviewToolTipPos({
      left: 0,
      top: 0,
    });
    setOverviewTooltipData();
    setShowOverviewTooltip(false);
  };

  const getTitle = () => {
    if (tile?.title === 'Social Unique Users') {
      return 'Unique Users';
    } else if (tile?.title === 'Total Results' && !eventCycle) {
      return 'Articles';
    } else if (tile?.title === 'Social Reach') {
      return 'Social Impressions';
    }
    return tile?.title;
  };

  return (
    <>
      <ResultInFigContainer key={tile?.title}>
        <ResultContainer>
          <ResultInNumberWrp>
            <ResultInNumber
              onMouseEnter={handleOverviewMouseEnter}
              onMouseMove={handleOverviewMouseMove}
              onMouseLeave={handleOverviewMouseLeave}
              style={{ opacity: eventCycle ? '0.3' : 1 }}
            >
              {addCountPrefix(tile?.data || 0)}
            </ResultInNumber>
            {!eventCycle &&
              !(
                parseInt(tile?.previousCount) === 0 &&
                parseInt(tile?.data) === 0
              ) && (
                <ResultIndicatorWpr isIncreased={tile.isIncreased}>
                  <IndicatorIconWrp>
                    {tile.isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
                  </IndicatorIconWrp>
                  <IndicatorPercentageWrp
                    isIncreased={tile.isIncreased}
                    onMouseEnter={handleMouseEnter}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                  >
                    {(parseInt(tile?.change) || 0)?.toLocaleString('en-US')}%
                  </IndicatorPercentageWrp>
                </ResultIndicatorWpr>
              )}
            {!eventCycle &&
              parseInt(tile?.previousCount) === 0 &&
              parseInt(tile?.data) === 0 && (
                <ResultIndicatorWpr
                  isIncreased={tile.isIncreased}
                  style={{
                    backgroundColor: coolGrayColorGradients.coolGray10,
                    color: coolGrayColorGradients.coolGray60,
                    paddingLeft: '4px',
                  }}
                >
                  <Minus />
                  <IndicatorPercentageWrp
                    isIncreased={tile.isIncreased}
                    style={{
                      backgroundColor: coolGrayColorGradients.coolGray10,
                      color: coolGrayColorGradients.coolGray60,
                      marginLeft: '4px',
                    }}
                  >
                    0.0%
                  </IndicatorPercentageWrp>
                </ResultIndicatorWpr>
              )}
          </ResultInNumberWrp>
          <ResultTitle>
            <div
              onMouseEnter={handleOverviewMouseEnter}
              onMouseMove={handleOverviewMouseMove}
              onMouseLeave={handleOverviewMouseLeave}
              // style={{
              //   marginTop: '0.3rem',
              // }}
              style={{ opacity: eventCycle ? '0.3' : 1 }}
            >
              {getTitle()}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              {(tile?.title === 'Traditional Reach' ||
                tile?.title === 'Total Reach') && (
                <ReachTooltip
                  content="Reach estimates the potential viewership of any particular article based on the number of visitors to the specific source on both desktop and mobile"
                  direction="right"
                  reachTop={true}
                >
                  <img
                    src={infoIcon}
                    alt="info"
                    style={{
                      marginLeft: '6px',
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                    onMouseEnter={(e) => e.stopPropagation()}
                    onMouseMove={(e) => e.stopPropagation()}
                    onMouseLeave={(e) => e.stopPropagation()}
                  />
                </ReachTooltip>
              )}
            </div>
          </ResultTitle>
          {eventCycle && (
            <Alert>
              Showing rough estimation of total results. The actual number may
              vary and would show once processing completes.
            </Alert>
          )}
        </ResultContainer>
      </ResultInFigContainer>
      {showInfoTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={infoTooltipPos}
          align={infoTooltipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
          vAlign={'top'}
        >
          <ResultsInfoToolTip />
        </PortalTooltip>
      )}
      {!eventCycle && showTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={toolTipPos}
          align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
          vAlign={'top'}
        >
          <SummaryTooltip tooltipData={tooltipData} />
        </PortalTooltip>
      )}
      {!eventCycle && showOverviewTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={overviewToolTipPos}
          align={
            overviewToolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
          }
          vAlign={'top'}
          boxShadow={'0px 8px 20px 0px rgba(0, 0, 0, 0.12)'}
        >
          <TooltipWrapper>
            <OverviewTooltip tooltipData={overviewTooltipData} />
          </TooltipWrapper>
        </PortalTooltip>
      )}
    </>
  );
};

Tile.propTypes = {
  tile: PropTypes.object,
  selectedMedia: PropTypes.string,
  eventCycle: PropTypes.bool,
};

const SummaryTooltip = ({
  tooltipData = { label: '', value: '', isIncreased: null, change: '' },
}) => {
  const {
    label = '',
    value = '',
    isIncreased = null,
    change = '',
  } = tooltipData;
  return (
    <TooltipWrapper>
      {/* <TooltipTitle>{label}</TooltipTitle>
      <TooltipValue>
        {label === 'Total AVE' && '~$'}
        {value?.toLocaleString('en-US')}
      </TooltipValue> */}
      <ChangeWrp>
        <Change isIncreased={isIncreased} isToolTip={true}>
          {isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
          {parseInt(change || 0)?.toLocaleString('en-US')}%{' '}
        </Change>{' '}
        <ChartLabelExtraSmallWrp
          style={{ color: coolGrayColorGradients.coolGray70 }}
        >
          {isIncreased
            ? 'Increase in comparison to previous period'
            : 'Decrease in comparison to previous period'}
        </ChartLabelExtraSmallWrp>
        <div style={{ marginTop: '6px' }}>
          <ChartLabelSmallWrp
            style={{ color: coolGrayColorGradients.coolGray70 }}
          >
            {label === 'Total Results' ? 'Previous Count' : 'Previous Value'}
          </ChartLabelSmallWrp>
          <ChartLabelLargeWrp style={{ color: 'black', marginTop: '2px' }}>
            {label === 'Total AVE' && '~$'}
            {tooltipData?.previousCount?.toLocaleString('en-US')}
          </ChartLabelLargeWrp>
        </div>
      </ChangeWrp>
    </TooltipWrapper>
  );
};

const OverviewTooltip = ({ tooltipData }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
      <ChartLabelSmallWrp style={{ color: coolGrayColorGradients.coolGray70 }}>
        {tooltipData?.label === 'Total Results'
          ? 'Total Articles'
          : tooltipData?.label}
      </ChartLabelSmallWrp>
      <ChartLabelLargeWrp style={{ color: 'black' }}>
        {tooltipData?.label === 'Total AVE' && '~$'}
        {tooltipData?.value?.toLocaleString('en-US')}
      </ChartLabelLargeWrp>
    </div>
  );
};

const ResultsInfoToolTip = () => {
  return (
    <ChartLabelSmallWrp
      style={{
        maxWidth: '150px',
        color: coolGrayColorGradients.coolGray80,
        padding: '10px',
      }}
    >
      The data that is shown here can be increased after processing.
    </ChartLabelSmallWrp>
  );
};

SummaryTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

OverviewTooltip.propTypes = {
  tooltipData: PropTypes.object,
};
