import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  GraphNoDataText,
  CommentarySection,

  // BottomDeswpr,
  // BottomInfowpr,
  // GraphTypeBtn,
  // GraphTypeBtnWrapper,
  IconBox,
  Iconwpr,
  LegendSection,
  LegendSectionV2,

  // Line,
  // GraphTypeBtn,
  // GraphTypeBtnWrapper,
  SlotBody,
  // SlotBodyTabBody,
  // SlotBodyTabWrp,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotOverviewWrapper,
  // SlotHeaderRight,
  // SlotSubTitle,
  SlotTitle,
  SlotTypeTitle,
  RefreshIconWrp,
  SlotSubTitle,
  TopThemeToggleWrp,
  StyledMarkdown,
  LegendBox,
  LegendLabel,
  WordCloudGradient,
  PredictiveDataWrp,
  PredictiveText,
  StyledDiv,
  ReloadButton,
  APIErrorWrp,
  // TopInfowpr,
} from '../index.sc';
// import Loader from '../../loader';
import ApiErrorIcon from '../../../assets/icons/ApiErrorIcon';
import PortalTooltip from '../../portal-tooltip';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import Edit2 from '../../../assets/icons/Edit2';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import GraphLegend from '../../graph-legend';
import GraphLegendOutlet from '../../graph-legend-outlet';
import GraphLegendV2 from '../../graph-legend-v2';
import GraphTooltip from '../../graph-tooltip';
import DashboardPopup from '../../dasboard-popup';
import EditGraphPopup from '../../edit-graph-popup';
import SlotOverview from './SlotOverview';
import CircularLoading from '../../../assets/icons/loading/circularLoading';
import { formatNumber, getSelectedTypes, trimmedData } from '../../../utils';
import { LinearLine } from '../../../graphs';
import SimpleReusableDropDown from '../../simple-dropdown';

import { useParams } from 'react-router-dom';
import RefreshIcon from '../../../assets/icons/RefreshIcon';
import Tooltip from '../../icon-tooltip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import ChartToolTip from '../../chart-tool-tip';
import ReachTooltip from '../../tooltip';
import { theme } from '../../../constants/theme';
import {
  coolGrayColorGradients,
  magentaColorGradients,
  purpleColorGradients,
} from '../../../constants/graph-colors';
import ToggleSwitch from '../../toggle-switch';
import { useSelector } from 'react-redux';
import { outletBreakDown } from '../../../graphs/utils/graphConst';
import { reader } from '../../../constants';
import ThreeDotsLoader from '../../three-dots-loader';
import { NewsGraphWrp } from '../../../pages/news-letter/newsletter-add-section/add-item-component/graph-item/index.sc';
import {
  EngagementContainer,
  EngagementLabel,
  GradientBar,
  StageContainer,
} from '../../advanced-dashboard/social-campaign/index.sc';
import SocialTooltip from '../../advanced-dashboard/social-campaign/tooltip';
import SelectDropdown from '../../select';
import ChartDropdown from '../../chart-dropdown';
import { Switchwpr } from '../../dashboard-saved-search-drawer/index.sc';
import Prompt, { ResetButton } from './Prompt';
import PopupMenu from './Popover';
import EnlargedCard from './EnlargedCard';
import Close from '../../../assets/icons/Close';
import CrossCircle from '../../../assets/icons/CrossCircle';
import SvgIcon from '../../circularSvgIcon';
import { Button } from '../../button';

const gridXTicksCount = 6;
// check media type filter
function determineMediaType(data) {
  // Check if the data object is empty
  if (Object.keys(data).length === 0) {
    return ['all'];
  }

  // Check if the mediaTypes array exists and is not empty
  if (data.mediaTypes && data.mediaTypes.length > 0) {
    const mediaLabels = data.mediaTypes.map((media) => media.label);
    const mediaArray = [];

    // Check for specific conditions and add them to the array
    if (mediaLabels.includes('Print')) {
      mediaArray.push('print');
    }
    if (mediaLabels.includes('Online')) {
      mediaArray.push('online');
    }
    if (mediaLabels.includes('Forums')) {
      mediaArray.push('forums');
    }
    if (mediaLabels.includes('Blogs')) {
      mediaArray.push('blogs');
    }
    if (mediaLabels.includes('Reviews')) {
      mediaArray.push('reviews');
    }

    if (mediaLabels?.includes('X (Twitter)')) {
      mediaArray.push('twitter');
    }
    if (mediaLabels?.includes('Reddit')) {
      mediaArray.push('reddit');
    }
    if (mediaLabels?.includes('YouTube')) {
      mediaArray.push('YouTube');
    }

    // If all media types are present, return 'all'
    if (mediaArray.length === 7) {
      return ['all'];
    }

    return mediaArray;
  }

  // Default case if none of the above conditions are met
  return ['all'];
}

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false,
  rerender,
  showAllSubCircles = false,
  themeComponent = false,
  filters = {},
  summaryComponentHeight = 0,
  storyAnalysisChart = false,
  predictiveCheck = false,
  isError = false,
  refetch = () => {}
) => {
  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };
  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget.component]) ||
    {};
  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.component
      : bentoView[type]?.component;

  const maxData =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.maxData
      : bentoView[type]?.maxData;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  let finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.config
      : bentoView[type]?.config),
    ...defaultConfig(widget?.customClassName),
    ...widgetDetails,
    ...(canvas && canvasConfig),
    ...(type === 'dashboard' ? {} : { handleOnClick: undefined }),
    summaryContainerHeight: summaryComponentHeight,
  };

  if (
    // finalConfig?.component === 'sentiment_over_time' ||
    finalConfig?.component === 'coverage_over_time' ||
    finalConfig?.component === 'reach_over_time' ||
    // finalConfig?.component === 'media_type' ||
    finalConfig?.component === 'coverage_over_time' ||
    finalConfig?.component === 'reach_over_time' ||
    finalConfig?.component === 'industry_coverage_by_source' ||
    finalConfig?.component === 'industry_coverage_over_time' ||
    finalConfig?.component === 'people_coverage_over_time' ||
    finalConfig?.component === 'people_media_type'
  ) {
    finalConfig = {
      ...finalConfig,
      singleLineWrp: false,
      showAllLabels: true,
    };
  }
  if (finalConfig.component === 'result_over_time') {
    finalConfig = {
      ...finalConfig,
      fromNewsletter: true,
    };
  }
  if (
    dashboardType === 'overview' &&
    (widget.graphType === 'pie' || widget.graphType === 'donut')
  ) {
    finalConfig = {
      ...finalConfig,
      fromExecutiveDashboard: true,
    };
  }
  if (
    finalConfig?.component === 'top_source' ||
    finalConfig?.component === 'industry_coverage_by_top_publications' ||
    finalConfig?.component === 'people_top_source_by_sentiment'
  ) {
    finalConfig = {
      ...finalConfig,
      capitalizeLabel: true,
    };
  }
  if (
    finalConfig?.component === 'top_author' ||
    finalConfig?.component === 'industry_coverage_by_journalist'
  ) {
    finalConfig = {
      ...finalConfig,
      colorGradientDark: theme?.light?.graphColors?.blue50,
      colorGradientLight: theme?.light?.graphColors?.cyan30,
      dropShadowColor: theme?.light?.graphColors?.cyan40,
      authorDataLength: widget?.data?.data?.length * 10,
      graphTopPadding: 0,
    };
  }

  if (finalConfig?.component === 'people_top_author') {
    finalConfig = {
      ...finalConfig,
      graphVersion: 'v2',
      authorDataLength: widget?.data?.data?.length * 10,
      graphTopPadding: 0,
    };
  }

  if (
    finalConfig?.component === 'competition_media_contributors' ||
    finalConfig?.component === 'competition_coverage_by_websites' ||
    finalConfig?.component === 'brand_engagement_metrics'
  ) {
    finalConfig = {
      ...finalConfig,
      valueInPercent: false,
      yAxisType: 'number',
      columnWidth: 40,
      isHorizontalBar: true,
    };
  }
  if (finalConfig?.component === 'toptheme-graph-download') {
    finalConfig = {
      ...finalConfig,
      renderKeyword: 3,
    };
  }
  if (finalConfig?.component === 'top_themes') {
    finalConfig = {
      ...finalConfig,
      enableSequenceBubble: true,
      showAllSubCircles,
      maxBubbleSize: themeComponent ? 80 : 120,
    };
  }
  if (
    finalConfig?.component === 'article_sentiment' ||
    finalConfig?.component === 'people_top_source_by_sentiment' ||
    finalConfig?.component === 'people_top_journalist_by_sentiment'
  ) {
    finalConfig = {
      ...finalConfig,
      greenGradientLight: theme?.light?.graphColors?.green40,
      greenGradientDark: theme?.light?.graphColors?.green50,
      redGradientLight: theme?.light?.graphColors?.red40,
      redGradientDark: theme?.light?.graphColors?.red50,
      grayGradientLight: theme?.light?.graphColors?.coolGray30,
      grayGradientDark: theme?.light?.graphColors?.coolGray40,
      greenDropShadowColor: theme?.light?.graphColors?.green50,
      redDropShadowColor: theme?.light?.graphColors?.red50,
      grayDropShadowColor: '#A8ADBD',
      barHeight: finalConfig?.component === 'article_sentiment' ? 24 : 16,
      articleSentiment: true,
    };
  }

  if (
    finalConfig?.component === 'competitive_reach_over_time' ||
    finalConfig?.component === 'competitive_coverage_over_time' ||
    finalConfig?.component === 'coverage_over_time' ||
    finalConfig?.component === 'reach_over_time' ||
    finalConfig?.component === 'industry_coverage_over_time' ||
    finalConfig?.component === 'people_coverage_over_time' ||
    finalConfig?.component === 'brand_seasonal_trends'
  ) {
    finalConfig = {
      ...finalConfig,
      overTimeChart: true,
      enableCurve: false,
      enableTooltipTrendPointer: true,
      enableGradient: !(
        finalConfig?.component === 'competitive_reach_over_time' ||
        finalConfig?.component === 'competitive_coverage_over_time' ||
        finalConfig?.component === 'brand_seasonal_trends'
      ),
    };
  }

  if (finalConfig?.component === 'breakdown_by_media_type') {
    finalConfig = {
      ...finalConfig,
      breakDownByMedia: true,
      enableGridYLine: false,
      columnWidth: 24,
      columnGroupPadding: 8,
      mediaType: determineMediaType(filters),
    };
  }

  if (finalConfig.component === 'outlet_breakdown') {
    finalConfig = {
      ...finalConfig,
      mediaType: determineMediaType(filters),
      isStackedOutlet: true,
      greenGradientLight: theme?.light?.graphColors?.green40,
      greenGradientDark: theme?.light?.graphColors?.green50,
      redGradientLight: theme?.light?.graphColors?.red40,
      redGradientDark: theme?.light?.graphColors?.red50,
      grayGradientLight: theme?.light?.graphColors?.coolGray30,
      grayGradientDark: theme?.light?.graphColors?.coolGray40,
      greenDropShadowColor: theme?.light?.graphColors?.green50,
      redDropShadowColor: theme?.light?.graphColors?.red50,
      articleSentiment: true,
      barHeight: 50,
      hideXAxis: true,
    };
  }

  if (
    finalConfig?.component === 'media_type' ||
    finalConfig?.component === 'industry_coverage_by_source' ||
    finalConfig?.component === 'people_media_type'
  ) {
    finalConfig = {
      ...finalConfig,
      singleLineWrp: false,
      showAllLabels: true,
      // columnWidth:
      //   finalConfig?.component === 'industry_coverage_by_source' ||
      //   finalConfig?.component === 'people_media_type'
      //     ? 16
      //     : 20,
      columnWidth: 20,
      columnGroupPadding: 4,
      mediaTypeChart: true,
      mediaType: determineMediaType(filters),
      enableCurve: false,
      enableTooltipTrendPointer: true,
    };
  }

  if (finalConfig?.component === 'sentiment_over_time') {
    finalConfig = {
      ...finalConfig,
      singleLineWrp: false,
      showAllLabels: true,
      sentimentOverTime: true,
    };
  }

  if (finalConfig?.component === 'people_top_source_by_sentiment') {
    finalConfig = {
      ...finalConfig,
      greenGradientLight: theme?.light?.graphColors?.green40,
      greenGradientDark: theme?.light?.graphColors?.green50,
      redGradientLight: theme?.light?.graphColors?.red40,
      redGradientDark: theme?.light?.graphColors?.red50,
      grayGradientLight: theme?.light?.graphColors?.coolGray30,
      grayGradientDark: theme?.light?.graphColors?.coolGray40,
      greenDropShadowColor: theme?.light?.graphColors?.green50,
      redDropShadowColor: theme?.light?.graphColors?.red50,
      grayDropShadowColor: '#A8ADBD',
      articleSentiment: true,
    };
  }

  if (widget?.customClassName === 'story_analysis_results_over_time') {
    finalConfig = {
      ...finalConfig,
      predictiveCheck,
    };
  }

  if (widget?.customClassName === 'story_analysis_top_themes') {
    finalConfig = {
      ...finalConfig,
      aspectRatio: 1,
      innerRadius: 0.175,
      outerRadius: 0.6,
      secondaryOuterRadius: 1,
      labelPadding: 10,
      opacity: 0.7,
    };
  }

  if (
    finalConfig?.component === 'competitive_reach_over_time' ||
    finalConfig?.component === 'competitive_coverage_over_time' ||
    finalConfig?.component === 'competitive_coverage_over_time_social' ||
    finalConfig?.component === 'competitive_reach_over_time_social'
  ) {
    finalConfig = {
      ...finalConfig,
      multiLineDrillDown: true,
    };
  }
  if (
    widget?.customClassName === 'people-reach-over-time-comparison' ||
    widget?.component === 'sentiment_over_time' ||
    widget?.component === 'sentiment_over_time_social'
  ) {
    finalConfig = {
      ...finalConfig,
      multiLineDrillDown: true,
    };
  }
  if (storyAnalysisChart) {
    finalConfig = {
      ...finalConfig,
      showAllLabels: false,
    };
  }

  return widget?.component === 'people_coverage_on_key_topics' && isError ? (
    <>
      <GraphNoDataText>
        <StyledDiv
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <ApiErrorIcon />

          <APIErrorWrp>
            An error occurred while loading the chart.
            <br /> Please try reloading.
          </APIErrorWrp>

          <ReloadButton
            onClick={(e) => {
              e.preventDefault();
              refetch();
            }}
          >
            Reload
          </ReloadButton>
        </StyledDiv>
      </GraphNoDataText>
    </>
  ) : widget?.shouldShowGraph ? (
    <GraphComponent
      data={trimmedData(
        widget?.customClassName === 'story_analysis_top_themes'
          ? widget.data?.data
          : widget?.data,
        maxData
      )}
      config={finalConfig}
      resetSelection={resetSelection}
      rerender={rerender}
      handleOnClick={defaultConfig?.handleOnClick}
    />
  ) : (
    <GraphNoDataText>No Data</GraphNoDataText>
  );
};

const SlotDetails = ({
  setVisibleInsights = () => {},
  loadedCharts,
  onPromptSubmit,
  promptData,
  graphVersion,
  handleSelectedChartDropdown = () => {},
  dashboardKeywords = [],
  selectedChartDropdown = {},
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  legend = false,
  commentary = false,
  resetSelection = false,
  handleOnClick = () => {},
  handleUpdatedChart = () => {},
  handleShowDownloadPopUp = () => {},
  overRideSlot,
  editOption = false,
  actionOption = false,
  downloadFunction,
  setSelectedComponent,
  selectedComponent,
  graphDownloading,
  widgetClassName,
  searchId,
  chartNames,
  handleGraphTitleUpdate = () => {},
  editChart,
  isSavePopup,
  chartRefresh = () => {},
  helperText = '',
  customGridDashboard = '',
  onDownloadChartData,
  themeComponent = false, // comes from newsletter
  filters = {},
  insight = {},
  insightLoading = false,
  footerMarginTop = '',
  donutWidth = false,
  newsSentimentChart = false,
  alignBreakDownLegends = false,
  widgetClassNameTwo = '',
  accessTypeViewUser = false,
  onClickDropdown,
  selectedOption,
  storyAnalysisChart = false,
  isError = false,
  refetch = () => {},
}) => {
  const { dashboardId } = useParams();
  const isFiltersArray = Array.isArray(filters);
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [tooltipEvent, setTooltipEvent] = useState({});
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget?.graphType || '',
  });
  const [rerender, setRerender] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [toggleChartTip, setToggleChartTip] = useState(false);
  const [showAllSubCircles, setShowAllSubCircles] = useState(
    themeComponent ?? false
  );
  const [text, setText] = useState(promptData?.[widgetClassName]?.prompt || '');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [cardData, setCardData] = useState(null); // Store the card's data
  // const [cardPosition, setCardPosition] = useState({
  //   top: 100,
  //   left: 100,
  //   width: 200,
  //   height: 150,
  // });

  // Scale the card to twice its size for the popup (example)

  // Function to calculate the popup position and dimensions
  const observer = useRef(null); // Store the observer
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [predictiveCheck, setPredictiveCheck] = useState(false);

  const handlePredictiveCheckBox = () => {
    setPredictiveCheck(!predictiveCheck);
  };

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const role = useSelector((state) => state?.user?.role);

  const getSelector = (state) => {
    if (dashboardType === 'primpact') {
      return state.prImpact;
    } else if (dashboardType === 'people') {
      return state.people;
    } else if (dashboardType === 'campaign') {
      return state.campaignMonitor;
    } else if (dashboardType === 'storyAnalysis') {
      return state.storyAnalysis;
    } else {
      return state.advancedDashboardTab;
    }
  };
  const { activeTab } = useSelector(getSelector);
  const mediaTypeActive = isFiltersArray
    ? determineMediaType(filters?.[activeTab] || {})
    : determineMediaType(filters || {});

  const containerRef = useRef(null);
  const downloadRef = useRef(null);

  const titleRef = useRef(null);
  const summaryHeightRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  const graphData = widget;

  const customDashboard = 'custom';

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setRerender((old) => !old);
  }, [widget]);

  // const handleDownloadSingleGraph = () => {
  //   downloadFunction();
  // };
  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
  ];

  // const [activeBtn, setActiveBtn] = useState('trendline');
  // console.log(widgetMapping[dashboardType][widget.component], 'dashboardType');

  // console.log(widgetMapping, widget.component, 'this is in slot details');
  const tooltipEnabled = true;

  const getLabelColor = (data) => {
    if (
      widgetClassName === 'geographical-graph-download' &&
      parseInt(data?.properties?.value) > 0
    ) {
      return purpleColorGradients.purple60;
    } else if (
      widgetClassName === 'geographical-graph-download' &&
      parseInt(data?.properties?.value) > 0
    ) {
      return coolGrayColorGradients.coolGray60;
    } else if (widgetClassName === 'outletmedia-graph-download') {
      return outletBreakDown[
        data?.parentData?.parentData?.value?.findIndex(
          (value) => value?.label === data?.parentData?.label
        )
        // ? 0
        // : data?.parentData?.label === 'Traditional' ||
        //   data?.parentData?.label === 'Online' ||
        //   data?.parentData?.label === 'x (twitter)' ||
        //   data?.parentData?.label === 'Reddit'
        // ? 0
        // : data?.parentData?.label === 'Print'
        // ? 1
        // : 5
      ];
    }
    return data?.labelColor || data?.rawData?.labelColor;
  };

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setTooltipEvent({
        event,
        d,
        i,
      });
      const tData = d.data || d;
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });

      setTooltipData({
        data: tData,
        rawData: d?.rawData,
        labelColor:
          getLabelColor(tData) || tData?.parentData?.parentData?.color,
      });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({
        event,
        d,
        i,
      });
      const tData = d.data || d;
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({});
      setToolTipPos({ left: 0, top: 0 });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const defaultConfig = (chartId) => {
    return {
      handleMouseEnter: (event, d, i) => handleMouseEnter(event, d, i, chartId),
      handleMouseMove: (event, d, i) => handleMouseMove(event, d, i, chartId),
      handleMouseLeave: (event, d, i) => handleMouseLeave(event, d, i, chartId),
      handleOnClick: (event, d) => handleOnClick(event, d, chartId),
    };
  };

  if (dashboardType === 'grid-dashboard') {
    delete defaultConfig.handleOnClick;
  }

  useEffect(() => {
    const handleScroll = () => {
      if (enableTooltip) {
        setEnableTooltip(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [enableTooltip]);

  // const handleTabBtnClick = (e, tab) => {
  //   e.stopPropagation();
  //   setActiveBtn(tab);
  // };

  // const reducedHeight =
  //   widget?.information && widget?.bottomDescription
  //     ? 3
  //     : widget?.information
  //     ? 2
  //     : widget?.bottomDescription
  //     ? 1
  //     : 0;

  const traditional = {
    type: 'Traditional',
    color: purpleColorGradients.purple60,
    dataKey: 'totalTraditionCount',
  };

  const social = {
    type: 'Social',
    color: magentaColorGradients.magenta50,
    dataKey: 'totalSocialCount',
  };

  const mediaMappings = {
    social: [
      {
        ...social,
        type: 'Results',
      },
    ],
    traditional: [
      {
        ...traditional,
        type: 'Results',
      },
    ],
    all: [social, traditional],
  };

  const summary = graphData?.data?.summary;
  let legendData = [];
  let selectedMedia = '';
  const twoD = graphData?.data?.labels?.length > 1;
  if (graphData?.data?.legends?.length > 0) {
    legendData = [...graphData.data.legends];
  } else {
    if (twoD) {
      if (
        graphData?.component === 'media_type' ||
        widget.component === 'industry_coverage_by_source' ||
        widget.component === 'people_media_type'
      ) {
        const mediaTypeName = graphData?.data?.summary?.mediaType;
        const filteredLegends = graphData?.data?.labels.filter((item) =>
          mediaTypeName === null ? item : mediaTypeName?.includes(item.label)
        );
        legendData = filteredLegends.map((ele) => ({
          label: ele?.label,
          value: ele?.label?.replaceAll(' ', '').toLowerCase(),
          color: ele?.color,
        }));
      } else if (graphData?.component === 'people_coverage_on_key_topics') {
        legendData = graphData?.data?.footerLabels.map((ele) => ({
          label: ele?.label,
          value: ele?.label?.replaceAll(' ', '').toLowerCase(),
          color: ele?.color,
        }));
      } else {
        legendData = graphData?.data?.labels.map((ele) => ({
          label: ele?.label,
          value: ele?.label?.replaceAll(' ', '').toLowerCase(),
          color: ele?.color,
        }));
      }
    } else {
      if (graphData?.component === 'result_over_time') {
        selectedMedia = getSelectedTypes(graphData?.data?.summary);
      } else {
        legendData = graphData?.data?.legends?.map((ele) => ({
          label: ele?.label,
          value: ele?.value,
          color: ele?.color,
        }));
      }
    }
  }

  const handleGraphEditClick = (e) => {
    // e.preventDefault();
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();
    // console.log(data, 'edit data');
    handleUpdatedChart(data);
    const chartData = chartNames?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      widgetClassName,
      data?.chartType
    );
    handleGraphEditClick(e);
    // if (searchId !== 'custom-search') {
    //   onUpdateChartNames(updatedChartData);
    // }
  };

  const handleEditChart = (data) => {
    setEditChart2(data);
  };

  const handleOptionIcon = (e, componentName) => {
    // e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev !== componentName ? componentName : false
    );
  };

  const showLineBar = () => {
    const { bentoView } =
      (widgetMapping[dashboardType] &&
        widgetMapping[dashboardType][graphData.component]) ||
      {};

    const GraphComponent =
      type === 'dashboard' || type === 'l2'
        ? graphTypes[graphData.graphType]?.component
        : bentoView[type]?.component;

    return GraphComponent === LinearLine;
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [editChart?.chartName, editChart2?.chartName]);

  const getSlotBodyStyles = () => {
    if (
      widgetClassName === 'geographical-graph-download' ||
      widgetClassName === 'volume_analysis' ||
      widgetClassName === 'toptheme-graph-download'
    ) {
      return { padding: '1rem' };
    } else if (graphData?.component === 'top_author') {
      return { padding: '0 1.25rem 2.5rem 1.25rem' };
    } else if (widgetClassName === 'wordcloud-graph-download') {
      return {
        paddingRight: '1rem',
      };
    }
    return {};
  };

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  const getOptions = (component) => {
    let filteredOptions;

    switch (component) {
      case 'sov':
        filteredOptions = [
          {
            value: 'volume',
            name: 'Volume',
            type: ['social', 'traditional', 'all'],
          },
          // {
          //   value: 'impression',
          //   name: 'Impression',
          //   type: ['social', 'all'],
          // },
          {
            value: 'social_reach',
            name: 'Social Reach',
            type: ['social', 'all'],
          },
          {
            value: 'traditional_reach',
            name: 'Traditional Reach',
            type: ['all', 'traditional'],
          },
          // {
          //   value: 'engagement',
          //   name: 'Engagement',
          //   type: ['social', 'all'],
          // },
        ]?.filter((x) => x?.type?.includes(getSelectedTypes(filters)));
        break;

      case 'brand_result_over_time':
        filteredOptions = [
          {
            value: 'reach',
            name: 'Reach',
            type: ['social', 'traditional', 'all'],
          },
          // {
          //   value: 'impression',
          //   name: 'Impression',
          //   type: ['social', 'traditional', 'all'],
          // },
          // {
          //   value: 'engagement',
          //   name: 'Engagement',
          //   type: ['social', 'traditional', 'all'],
          // },
        ];
        break;

      case 'brand_seasonal_trends':
        filteredOptions = [
          {
            value: 'mention',
            name: 'Mention',
            type: ['social', 'all'],
          },
          {
            value: 'engagement',
            name: 'Engagement',
            type: ['social', 'all'],
          },
        ];
        break;
      default:
        filteredOptions = [];
        break;
    }

    return filteredOptions;
  };

  const onOpenPopup = (event, data) => {
    // Get the container using an ID or class
    const container = document.querySelector('#container');
    const containerRect = container.getBoundingClientRect(); // Container bounds

    const cardRect = event.target.closest('.card').getBoundingClientRect(); // Ensure the card is targeted

    // Calculate position relative to the container
    const cardPosition = {
      top: cardRect.top - containerRect.top,
      left: cardRect.right - cardRect.left,
      width: cardRect.width,
      height: cardRect.height,
    };

    // Decide the expansion direction based on the card's position
    const isCloserToLeft =
      cardRect.left + cardRect.width / 2 < containerRect.width / 2;

    setCardData({
      index: 0,
      position: cardPosition,
      direction: isCloserToLeft ? 'right' : 'left',
    });
    setIsPopupOpen((old) => !old);
  };

  // const closePopup = () => {
  //   setIsPopupOpen(false);
  //   setCardData(null); // Reset card data
  // };

  // track onview charts
  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const chartName = entry.target.getAttribute('data-chart-name');
          if (
            entry.isIntersecting &&
            loadedCharts &&
            !loadedCharts.has(chartName)
          ) {
            // Debounce the state update to avoid multiple rapid state updates
            if (debounceTimeout) {
              clearTimeout(debounceTimeout);
            }

            // Set a new debounce timeout
            const newTimeout = setTimeout(() => {
              setVisibleInsights((prev) => new Set(prev.add(chartName)));
            }, 300);

            setDebounceTimeout(newTimeout);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    return () => {
      // Clean up observer and timeout on component unmount
      if (observer.current) {
        observer.current.disconnect();
      }
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  const observeElement = (element) => {
    if (element && observer.current) {
      observer.current.observe(element);
    }
  };
  const graph = (
    <SlotDetailsWrp className="sentiment-graph-download insight-section">
      {!storyAnalysisChart && (
        <SlotHeader className="hide-download">
          <SlotHeaderLeft
            style={{ marginLeft: !isPopupOpen && '1.5rem' }}
            data-chart-name={widget?.component}
            ref={observeElement}
          >
            <SlotTitle ref={titleRef}>
              {dashboardId
                ? editChart?.chartName || widget?.title
                : editChart2?.chartName || widget?.title}
              {(dashboardType === 'custom' ||
                customGridDashboard === 'custom') && (
                <SlotTypeTitle
                  type={widgetMapping[customDashboard][widget.component]?.type}
                >
                  {widgetMapping[customDashboard][
                    widget.component
                  ]?.type?.toUpperCase()}
                </SlotTypeTitle>
              )}
            </SlotTitle>
            {actionOption &&
            (widgetClassName === 'competitive_reach_over_time' ||
              widgetClassName === 'reach_over_time') ? (
              <ReachTooltip
                content="Reach estimates the potential viewership of any particular article based on the number of visitors to the specific source on both desktop and mobile"
                direction="right"
              >
                {activeTab !== 999 && <HelpIcon />}
              </ReachTooltip>
            ) : (
              actionOption && (
                <>
                  <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() => setToggleChartTip(true)}
                    onMouseLeave={() => setToggleChartTip(false)}
                  >
                    {activeTab !== 999 && <HelpIcon />}
                  </span>
                  {/* <SlotSubTitle>{widget.subTitle}</SlotSubTitle> */}
                  {toggleChartTip && (
                    <ChartToolTip
                      text={helperText}
                      dashboardType={dashboardType === 'custom'}
                      componentLeft={iconPosition?.left}
                      componentTop={8.5}
                    />
                  )}
                </>
              )
            )}
            {/* {promptData[widgetClassName] && <PopupMenu />} */}
          </SlotHeaderLeft>

          <IconBox
            type={type}
            isSavePopup={isSavePopup}
            style={{ marginRight: !isPopupOpen && '1.5rem' }}
          >
            {/* <Iconwpr width={'1.5rem'} height={'1.5rem'} onClick={handleClick}>
          <ExpandIcon />
        </Iconwpr> */}
            {/* {overRideSlot && (
          <Iconwpr onClick={handleShowDownloadPopUp}>
            <ComponentIcon color="gray" />
          </Iconwpr>
        )} */}
            {(widget?.component === 'sov' ||
              widget?.component === 'brand_result_over_time' ||
              widget?.component === 'brand_seasonal_trends') &&
              dashboardType !== 'grid-dashboard' && (
                <RefreshIconWrp>
                  <SelectDropdown
                    options={getOptions(widget?.component)}
                    selectedOption={selectedOption}
                    handleSelect={(option) => {
                      onClickDropdown &&
                        onClickDropdown(option, widget?.component);
                    }}
                  />
                </RefreshIconWrp>
              )}

            {widget?.component === 'top_themes' &&
              actionOption &&
              dashboardType === 'overview' && (
                <Tooltip content="refresh">
                  <RefreshIconWrp onClick={chartRefresh}>
                    <RefreshIcon />
                  </RefreshIconWrp>
                </Tooltip>
              )}

            {dashboardType === 'overview' &&
              widget?.shouldShowGraph &&
              !isPopupOpen &&
              editOption &&
              role !== reader &&
              !accessTypeViewUser && (
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={(event) => {
                    onOpenPopup(event, widget);
                  }}
                  className="hide-downloading"
                >
                  <AIIcon />
                </Iconwpr>
              )}

            {(widget?.component === 'competition_media_contributors' ||
              widget?.component === 'competition_coverage_by_websites') &&
              dashboardType !== 'grid-dashboard' && (
                <ChartDropdown
                  handleSelectedChartDropdown={handleSelectedChartDropdown}
                  chartName={widget?.component}
                  dashboardKeywords={dashboardKeywords}
                  selectedChartDropdown={selectedChartDropdown}
                />
              )}

            {widget?.customClassName === 'story_analysis_results_over_time' &&
              widget?.shouldShowGraph && (
                <PredictiveDataWrp className="hide-downloading">
                  <PredictiveText>Predictive Extend</PredictiveText>
                  <Switchwpr
                    type="checkbox"
                    id="switch"
                    onChange={handlePredictiveCheckBox}
                    checked={predictiveCheck}
                  />
                </PredictiveDataWrp>
              )}

            {(widget?.shouldShowGraph &&
              !isPopupOpen &&
              editOption &&
              role !== reader &&
              !accessTypeViewUser && (
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={handleGraphEditClick}
                  className="hide-downloading"
                >
                  <Edit2 />
                </Iconwpr>
              )) || <></>}

            {(widget?.shouldShowGraph &&
              actionOption &&
              role !== reader &&
              isPopupOpen === false && (
                <>
                  <Iconwpr
                    width={'1.5rem'}
                    height={'1.5rem'}
                    onClick={(e) => {
                      handleOptionIcon(e, graphData.component);
                    }}
                    ref={downloadRef}
                    className="hide-downloading"
                  >
                    <VerticleDots
                      color={
                        openActionDropdown === selectedComponent
                          ? '#675ef2'
                          : '#5C5E60'
                      }
                    />
                    <SimpleReusableDropDown
                      isOpen={openActionDropdown === selectedComponent}
                      options={actionDropDownOptions}
                      graphDownloading={graphDownloading}
                      setIsOpen={setOpenActionDropDown}
                    />
                  </Iconwpr>
                </>
              )) ||
              (isPopupOpen && (
                <>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPopupOpen(false);
                    }}
                  >
                    <CrossCircle color="rgba(0, 0, 0, 0.1)" />
                  </div>
                </>
              ))}
          </IconBox>

          {/* {widget.enableTabs && (
            <SlotHeaderRight>
              <GraphTypeBtnWrapper>
                <GraphTypeBtn
                  className={activeBtn === 'trendline' ? 'active' : ''}
                  onClick={(e) => handleTabBtnClick(e, 'trendline')}
                >
                  Trendline
                </GraphTypeBtn>
                <GraphTypeBtn
                  className={activeBtn === 'bar' ? 'active' : ''}
                  onClick={(e) => handleTabBtnClick(e, 'bar')}
                >
                  Bar
                </GraphTypeBtn>
              </GraphTypeBtnWrapper>
            </SlotHeaderRight>
          )} */}
        </SlotHeader>
      )}
      {!storyAnalysisChart && type === 'dashboard' && (
        <>
          <span
            style={{
              position: 'relative',
              opacity: submitted ? 0.3 : 1,
            }}
          >
            <SlotOverviewWrapper
              style={{
                marginBottom: '10px',
                marginLeft: !isPopupOpen && '1.5rem',
                position: 'relative',
                // zIndex: 1,
              }}
              className="hide-download"
            >
              {widget?.graphType !== 'uber_stats_volume' && (
                <SlotOverview summary={graphData?.data?.summary} />
              )}
            </SlotOverviewWrapper>
          </span>
          {submitted && (
            <span
              style={{
                position: 'absolute',
                top: '20px',
                left: 0,
                width: '100%',
                height: isPopupOpen ? '65%' : '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                zIndex: 2,
              }}
            ></span>
          )}
        </>
      )}

      <SlotBody
        type={type}
        className={`${type === 'dashboard' && legend ? 'legend' : ''} ${
          type === 'dashboard' && commentary ? 'commentary' : ''
        } ${widgetClassName + '-body'}`}
        style={{
          position: 'relative',
          opacity: submitted ? 0.9 : 1,
          ...getSlotBodyStyles(),
        }}
      >
        {legend &&
          legendData?.length <= 6 &&
          (graphData?.graphType === 'pie' ||
            graphData?.graphType === 'donut') &&
          graphData?.shouldShowGraph && (
            <LegendSectionV2
              length={
                dashboardType === 'storyAnalysis' &&
                widget?.customClassName === 'story_analysis_media_channels'
                  ? mediaTypeActive?.toString() === 'all'
                    ? 7
                    : mediaTypeActive?.length
                  : legendData?.length
              }
              type={type}
              newsSentimentChart={newsSentimentChart}
              dashboardType={dashboardType}
            >
              <GraphLegendV2
                dashboardType={dashboardType}
                legendData={graphData?.data?.data}
                mediaType={
                  isFiltersArray
                    ? determineMediaType(filters?.[activeTab])
                    : determineMediaType(filters)
                }
              />
            </LegendSectionV2>
          )}
        {loader ? (
          <CircularLoading size="0.25rem" width="1.875rem" height="1.875rem" />
        ) : (
          <NewsGraphWrp width={donutWidth}>
            {generateGraphComponent(
              graphData,
              defaultConfig,
              type,
              dashboardType,
              canvas,
              resetSelection,
              rerender,
              showAllSubCircles,
              themeComponent,
              filters,
              summaryHeightRef?.current?.clientHeight,
              storyAnalysisChart,
              predictiveCheck,
              isError,
              refetch
            )}
          </NewsGraphWrp>
        )}
        {/* {showLineBar() && Object.keys(tooltipEvent).length > 0 && (
            <LineShow xAxis={tooltipEvent?.event?.offsetX + 2}></LineShow>
          )} */}
        {!submitted && tooltipEnabled && enableTooltip && (
          <PortalTooltip
            isOpen={true}
            pos={toolTipPos}
            align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
            vAlign={toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'}
            isAlign={true}
            boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
          >
            {graphData?.component === 'advanced_content_engagement' ||
            graphData?.component === 'brand_result_over_time' ||
            graphData?.component === 'brand_seasonal_trends' ? (
              <SocialTooltip widget={graphData} tooltipData={tooltipData} />
            ) : (
              <GraphTooltip
                tooltipData={tooltipData}
                type="two-d"
                widget={graphData}
              />
            )}
          </PortalTooltip>
        )}

        {submitted && (
          <span
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
              height: isPopupOpen && '285px',
              width: isPopupOpen && '676px',
            }}
          >
            <SvgIcon
              size="2.5rem"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
              }}
            />
          </span>
        )}
      </SlotBody>
      {widget?.component === 'top_themes' &&
        dashboardType === 'storyAnalysis' &&
        !themeComponent && (
          <TopThemeToggleWrp className="hide-download">
            <SlotSubTitle>Show Secondary Themes</SlotSubTitle>
            <ToggleSwitch
              accentColor={theme[selectedTheme].primary}
              checked={showAllSubCircles}
              onChange={() => setShowAllSubCircles(!showAllSubCircles)}
              tempWidth="2.5rem"
              tempHeight="1.25rem"
              toggleWidth="1rem"
              toggleHeight="1rem"
            />
          </TopThemeToggleWrp>
        )}
      {legend &&
        widget?.component !== 'top_themes' &&
        widget?.component !== 'industry_coverage_by_top_publications' &&
        widget?.component !== 'top_source' &&
        widget?.component !== 'top_source_social' && (
          <SlotFooter
            style={{
              paddingLeft: '1.5rem',
              paddingRight: '1.5rem',
              marginTop: footerMarginTop,
            }}
          >
            {legend &&
              legendData?.length <= 8 &&
              graphData?.graphType !== 'pie' &&
              graphData?.graphType !== 'donut' &&
              graphData?.component !== 'outlet_breakdown' &&
              graphData?.component !== 'word_cloud' && (
                <LegendSection>
                  <GraphLegend legendData={legendData} />
                </LegendSection>
              )}

            {graphData?.component === 'word_cloud' && (
              <LegendSection>
                <GraphLegend legendData={legendData} />
              </LegendSection>
            )}

            {legend && graphData?.component === 'outlet_breakdown' && (
              <LegendSection
                justify="flex-start"
                alignItem={alignBreakDownLegends}
                className="hide-download"
              >
                <GraphLegendOutlet legendData={graphData?.data?.data} />
              </LegendSection>
            )}
            {legend &&
              graphData?.component === 'advanced_content_engagement' && (
                <StageContainer right={true}>
                  <EngagementContainer>
                    <EngagementLabel>Low Engagement</EngagementLabel>
                    <GradientBar />
                    <EngagementLabel>High Engagement</EngagementLabel>
                  </EngagementContainer>
                </StageContainer>
              )}
            {graphData?.component === 'brand_social_word_cloud' && (
              <StageContainer right={true} style={{ width: '80%' }}>
                <EngagementContainer>
                  <EngagementLabel>Fading</EngagementLabel>
                  <WordCloudGradient />
                  <EngagementLabel>Trending</EngagementLabel>
                </EngagementContainer>
              </StageContainer>
            )}
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              {selectedMedia === 'all' &&
                mediaMappings.all.map((item) => {
                  return (
                    <React.Fragment key={item.type}>
                      <LegendBox bgColor={item.color} />
                      &nbsp;
                      <LegendLabel>
                        {item.type} :{' '}
                        {summary?.[item.dataKey]
                          ? formatNumber(summary[item.dataKey])
                          : 0}
                      </LegendLabel>
                      <LegendLabel> &nbsp; | &nbsp;</LegendLabel>
                    </React.Fragment>
                  );
                })}
              {selectedMedia === 'traditional' && (
                <p
                  style={{
                    color: coolGrayColorGradients.coolGray60,
                    fontSize: '11px',
                    margin: 0,
                    padding: 0,
                    fontWeight: 500,
                  }}
                >
                  Trendline for traditional channels
                </p>
              )}
              {selectedMedia === 'social' && (
                <p
                  style={{
                    color: coolGrayColorGradients.coolGray60,
                    fontSize: '11px',
                    margin: 0,
                    padding: 0,
                    fontWeight: 500,
                  }}
                >
                  Trendline for social channels
                </p>
              )}
            </div>
          </SlotFooter>
        )}
      {dashboardType === 'overview' && (
        <>
          {!isPopupOpen && (
            <div>
              <CommentarySection
                className="hide-download"
                ref={summaryHeightRef}
              >
                {!insightLoading ? (
                  <StyledMarkdown>{insight?.data?.summary}</StyledMarkdown>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      minHeight: '3rem',
                      alignItems: 'center',
                    }}
                  >
                    <ThreeDotsLoader />
                  </div>
                )}
              </CommentarySection>
            </div>
          )}
          {isPopupOpen && (
            <StyledDiv
            //  padding="8px"
            >
              <StyledDiv flex justifyContent="flex-end" marginBottom="8px">
                <StyledDiv flex gap="10px">
                  {' '}
                  {submitted && (
                    <StyledDiv>
                      <ResetButton
                        disabled={!text}
                        onClick={() => {
                          setText('');
                          onPromptSubmit('');
                          setSubmitted(false);
                        }}
                      >
                        Cancel
                      </ResetButton>
                    </StyledDiv>
                  )}
                  <StyledDiv>
                    <ResetButton
                      disabled={text === '' || submitted}
                      onClick={() => {
                        setText('');
                        onPromptSubmit('');
                        setIsPopupOpen(false);
                      }}
                    >
                      Reset
                    </ResetButton>
                  </StyledDiv>
                </StyledDiv>
              </StyledDiv>
              <Prompt
                onPromptSubmit={(prompt) => {
                  onPromptSubmit({
                    prompt,
                    graphId: widgetClassName,
                  });
                }}
                text={text}
                setText={setText}
                data={promptData[widgetClassName]}
                title={`${widget?.title}`}
                submitted={submitted}
                setSubmitted={setSubmitted}
              />
            </StyledDiv>
          )}
        </>
      )}
    </SlotDetailsWrp>
  );

  return (
    <SlotDetailsMainWrp
      type={type}
      ref={containerRef}
      className={widgetClassName + ' ' + widgetClassNameTwo + ' card'}
    >
      {/* <button onClick={() => setResetSelection(true)}>reset</button> */}

      {graph}
      {/* {isPopupOpen && (
        <PopupContainer
          style={{
            top: `${cardData.position.top}px`,
            left: `${
              cardData.direction === 'right'
                ? cardData.position.left
                : cardData.position.left - cardData.position.width * 2
            }px`,
            width: `${cardData.position.width * 2}px`,
            height: `${cardData.position.height * 2}px`,
          }}
        ></PopupContainer>
      )} */}
      {isPopupOpen && (
        <EnlargedCard
          top={'20%'}
          left={'30px'}
          width={'800px'}
          height={'548px'}
          setShow={setIsPopupOpen}
          animationDirection={cardData?.direction}
        >
          {graph}
        </EnlargedCard>
      )}
      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={editChart?.chartName}
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
    </SlotDetailsMainWrp>
    // <Line />
    // <BottomInfowpr>
    //     {widget?.bottomInformation ||
    //       'Insights : Media engagement of “covid vaccine” increased by 22% in jan 2021 “covid vaccine” increased by 22% in jan 2021'}
    //   </BottomInfowpr>
  );
};

SlotDetails.defaultProps = {
  type: 'dashboard',
};

SlotDetails.propTypes = {
  onPromptSubmit: Proptypes.object,
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  legend: Proptypes.bool,
  commentary: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  handleShowDownloadPopUp: Proptypes.func,
  overRideSlot: Proptypes.bool,
  editOption: Proptypes.bool,
  actionOption: Proptypes.bool,
  downloadFunction: Proptypes.func,
  setSelectedComponent: Proptypes.func,
  graphDownloading: Proptypes.bool,
  widgetClassName: Proptypes.string,
  searchId: Proptypes.string,
  chartNames: Proptypes.object || undefined,
  handleGraphTitleUpdate: Proptypes.func,
  editChart: Proptypes.object,
  isSavePopup: Proptypes.bool,
  chartRefresh: Proptypes.func,
  helperText: Proptypes.string,
  customGridDashboard: Proptypes.string,
  onDownloadChartData: Proptypes.func,
  themeComponent: Proptypes.bool,
  filters: Proptypes.object,
  insight: Proptypes.object,
  insightLoading: Proptypes.bool,
  footerMarginTop: Proptypes.string,
  donutWidth: Proptypes.bool,
  newsSentimentChart: Proptypes.bool,
  alignBreakDownLegends: Proptypes.bool,
  widgetClassNameTwo: Proptypes.string,
  selectedComponent: Proptypes.string,
  accessTypeViewUser: Proptypes.bool,
  onClickDropdown: Proptypes.func,
  selectedOption: Proptypes.object,
  handleSelectedChartDropdown: Proptypes.func,
  dashboardKeywords: Proptypes.array,
  selectedChartDropdown: Proptypes.object,
  storyAnalysisChart: Proptypes.bool,
  promptData: Proptypes.object,
  graphVersion: Proptypes.object,
  setVisibleInsights: Proptypes.func,
  loadedCharts: Proptypes.array,
  isError: Proptypes.bool,
  refetch: Proptypes.func,
};

const AIIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18018_10461)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2308 24H-3.23047C-0.350515 19.7745 4.50082 17 10.0002 17C15.4995 17 20.3509 19.7745 23.2308 24Z"
          fill="#878D96"
        />
        <path
          d="M15 1L15.0976 1.39546C15.4028 2.63182 16.3682 3.59716 17.6045 3.90237L18 4L17.6045 4.09763C16.3682 4.40284 15.4028 5.36818 15.0976 6.60454L15 7L14.9024 6.60454C14.5972 5.36818 13.6318 4.40284 12.3955 4.09763L12 4L12.3955 3.90237C13.6318 3.59716 14.5972 2.63182 14.9024 1.39546L15 1Z"
          fill="#878D96"
        />
        <path
          d="M8 3L8.66678 5.70096C8.98726 6.99914 10.0009 8.01274 11.299 8.33322L14 9L11.299 9.66678C10.0009 9.98726 8.98726 11.0009 8.66678 12.299L8 15L7.33322 12.299C7.01274 11.0009 5.99914 9.98726 4.70096 9.66678L2 9L4.70096 8.33322C5.99914 8.01274 7.01274 6.99914 7.33322 5.70096L8 3Z"
          fill="#878D96"
        />
      </g>
      <defs>
        <clipPath id="clip0_18018_10461">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SlotDetails;
