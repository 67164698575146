/* eslint-disable */
import * as d3 from 'd3';
import { setUpEvents } from '../utils/graphEvents';
import {
  magentaColorGradients,
  purpleColorGradients,
  tealColorGradients,
} from '../../constants/graph-colors';

export const concentricPie = function concentricPie() {
  let config = {
    aspectRatio: 1,
    innerRadius: 0.175,
    outerRadius: 0.6,
    secondaryOuterRadius: 1,
    labelPadding: 10,
    opacity: 0.7,
    maxCharsPerLine: 15,
    lineSpacing: 12,
  };

  const colorPalette = [
    tealColorGradients.teal50,
    purpleColorGradients.purple60,
    magentaColorGradients.magenta50,
  ];

  // hasEnoughSpaceForLabel to consider dynamic font size
  function hasEnoughSpaceForLabel(d, radius, fontSize, text) {
    const arcLength = Math.abs(d.endAngle - d.startAngle) * radius;
    const textWidth = text?.length * (fontSize * 0.6);
    return arcLength > textWidth;
  }

  // Modify font size calculation to be context-aware
  function calculateOptimalFontSize(text, arcLength, isPrimary = false) {
    // Add padding to account for text boundaries
    const TEXT_PADDING = 20; // Pixels of padding for text containment
    const adjustedArcLength = arcLength - TEXT_PADDING;

    // Set different size ranges for primary and secondary labels
    const actualMinSize = isPrimary ? 10 : 10; // Primary labels shouldn't go below 12px
    const actualMaxSize = isPrimary ? 18 : 18; // Secondary labels shouldn't exceed 14px

    // Start with maximum size and reduce until text fits
    for (let size = actualMaxSize; size >= actualMinSize; size--) {
      const textWidth = text?.length * (size * 0.6); // Approximate text width
      if (textWidth <= adjustedArcLength) {
        return size;
      }
    }
    return actualMinSize;
  }

  // Modify renderPerpendicularLabel to use dynamic font size
  function renderPerpendicularLabel(group, d, radius, text, i, arc, isPrimary) {
    group.selectAll('.perpendicular-label-' + i).remove();
    const arcLength = Math.abs(d.endAngle - d.startAngle) * radius;
    const fontSize = calculateOptimalFontSize(text, arcLength);
    const angleDiff = (d.endAngle - d.startAngle) * (180 / Math.PI);

    if (angleDiff < 10) return;

    let numLines = 1;
    if (isPrimary) {
      if (angleDiff >= 50) numLines = 3;
      else if (angleDiff >= 30) numLines = 2;
      else if (angleDiff <= 30) numLines = 1;
    } else {
      if (angleDiff >= 25) numLines = 3;
      else if (angleDiff >= 15) numLines = 2;
    }

    const maxChars = isPrimary ? 12 : 8;
    const words = text?.split(' ') || [];
    const lines = [];
    let currentLine = '';

    for (let i = 0; i < words?.length; i++) {
      const word = words[i];
      if (lines.length === numLines - 1) {
        const remainingWords = currentLine + (currentLine ? ' ' : '') + word;
        if (remainingWords.length > maxChars) {
          lines.push(currentLine.slice(0, maxChars) + '...');
        } else {
          lines.push(
            i === words.length - 1 ? remainingWords : remainingWords + '...'
          );
        }
        break;
      } else if (currentLine.length + word.length + 1 <= maxChars) {
        currentLine += (currentLine ? ' ' : '') + word;
      } else {
        if (currentLine) lines.push(currentLine);
        currentLine =
          word.length > maxChars ? word.slice(0, maxChars) + '...' : word;
      }
    }

    if (currentLine && lines.length < numLines) {
      lines.push(currentLine);
    }

    const textContainer = group
      .append('g')
      .attr('class', `perpendicular-label-${i}`);

    const [x, y] = arc.centroid(d);
    const rotation = (((d.startAngle + d.endAngle) / 2) * 180) / Math.PI;
    const scaleFactor = isPrimary ? 0.6 : 0.85;
    const transform = `translate(${x * scaleFactor},${
      y * scaleFactor
    }) rotate(${rotation - 90})`;

    textContainer.attr('transform', transform);

    // Calculate the vertical offset to center all lines
    const lineHeight = 1.2; // em units
    const totalHeight = lines.length * lineHeight;
    const startY = -(totalHeight / 2) + lineHeight / 2 + 0.3; // extra offset if required

    lines.forEach((line, lineIndex) => {
      textContainer
        .append('text')
        .style('pointer-events', 'none')
        .attr('dy', `${startY + lineIndex * lineHeight}em`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'start')
        .text(line)
        .style('fill', 'white')
        .style('font-size', `${fontSize}px`);
    });

    textContainer.append('title').text(text);
  }

  // Helper function to remove inner arc path
  function removeInnerArc(path) {
    return path.replace(/(M.*A.*)(A.*Z)/, (_, m1) => m1 || path);
  }

  // Helper function to split text into max 3 lines with ellipsis if needed
  function splitTextIntoLines(text, maxCharsPerLine) {
    const words = text?.split(' ');
    const lines = [];
    let currentLine = '';
    const MAX_LINES = 3;

    for (let i = 0; i < words?.length; i++) {
      const word = words[i];
      if (currentLine.length + word.length + 1 <= maxCharsPerLine) {
        currentLine += (currentLine ? ' ' : '') + word;
      } else {
        if (lines.length === MAX_LINES - 1) {
          // If we're about to add the third line, add ellipsis and stop
          if (currentLine) {
            lines.push(currentLine + '...');
          }
          break;
        } else {
          if (currentLine) lines.push(currentLine);
          currentLine = word;
        }
      }
    }

    // Handle the last line
    if (currentLine && lines.length < MAX_LINES) {
      if (
        lines?.length === MAX_LINES - 1 &&
        words?.length >
          words.indexOf(currentLine.split(' ')[0]) +
            currentLine.split(' ').length
      ) {
        // If there are more words after this line, add ellipsis
        lines.push(currentLine + '...');
      } else {
        lines.push(currentLine);
      }
    }

    return lines;
  }

  // Helper function to create text paths for multiple lines
  function createMultiLinePaths(group, d, radius, lineCount, i, config) {
    const lineHeight = config.lineSpacing;
    const paths = [];
    const MAX_LINES = 3;

    // Dynamic padding based on number of lines
    const getPadding = (count) => {
      switch (count) {
        case 1:
          return 10;
        case 2:
          return 15;
        case 3:
          return 10;
        default:
          return 10;
      }
    };

    // Limit lineCount to maximum of 3
    const actualLineCount = Math.min(lineCount, MAX_LINES);
    const TEXT_PADDING = getPadding(actualLineCount);

    for (let line = 0; line < actualLineCount; line++) {
      // Add dynamic TEXT_PADDING to move starting position of text outward
      const adjustedRadius = radius + TEXT_PADDING - line * lineHeight;
      const textArc = d3
        .arc()
        .innerRadius(adjustedRadius)
        .outerRadius(adjustedRadius)
        .startAngle(d.startAngle)
        .endAngle(d.endAngle);

      // Create path for each line
      group
        .append('defs')
        .append('path')
        .attr(
          'id',
          `text-path-${i}-line-${line}-${
            config?.className ? config.className : ''
          }`
        )
        .attr('d', removeInnerArc(textArc()))
        .attr('fill', 'none');

      paths.push(
        `text-path-${i}-line-${line}-${
          config?.className ? config.className : ''
        }`
      );
    }

    return paths;
  }

  // Modify renderSmartLabel to handle primary/secondary differently
  function renderSmartLabel(
    group,
    d,
    radius,
    text,
    i,
    config,
    arc,
    isPrimary = false
  ) {
    const arcLength = Math.abs(d.endAngle - d.startAngle) * radius;
    const lines = splitTextIntoLines(text, config.maxCharsPerLine);

    // Calculate optimal font size based on whether it's primary or secondary
    const totalText = lines.join(' ');
    const fontSize = calculateOptimalFontSize(totalText, arcLength, isPrimary);

    const angleDiff = (d.endAngle - d.startAngle) * (180 / Math.PI);
    // Increase minimum angle for primary labels
    const minAngle = isPrimary ? 25 : 35;
    const canFitInArc =
      angleDiff >= minAngle &&
      lines.every((line) => hasEnoughSpaceForLabel(d, radius, fontSize, line));
    // Remove any existing elements for this group
    group.selectAll('text').remove();
    group.selectAll('defs').remove();
    if (canFitInArc) {
      // Adjust padding based on primary/secondary
      const TEXT_PADDING = isPrimary ? 0 : -10;
      const adjustedRadius = radius + TEXT_PADDING;
      // Create a single defs element for the group
      const paths = createMultiLinePaths(
        group,
        d,
        adjustedRadius,
        lines.length,
        i,
        config
      );

      lines.forEach((line, lineIndex) => {
        group
          .append('text')
          .style('pointer-events', 'none')
          .append('textPath')
          .attr('xlink:href', `#${paths[lineIndex]}`)
          .attr('startOffset', '50%')
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'middle')
          .style('fill', 'white')
          .style('font-size', `${fontSize}px`)
          .text(line);
      });
    } else {
      renderPerpendicularLabel(group, d, radius, text, i, arc, isPrimary);
    }
  }

  function chart(selection) {
    selection.each(function (data) {
      const container = d3.select(this);
      const containerWidth =
        container.node().getBoundingClientRect().width || 300;
      const containerHeight = 350;
      const radius = Math.min(containerWidth, containerHeight) / 2;
      // SVG setup with more efficient selection
      const svg = container.selectAll('svg').data([null]);
      const svgEnter = svg.enter().append('svg');
      const svgMerge = svgEnter.merge(svg);

      svgMerge
        .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .attr(
          'width',
          config?.dashboardType === 'people' ||
            config?.dashboardType === 'storyAnalysis' ||
            config?.dashboardType === 'overview' ||
            config?.dashboardType === 'grid-dashboard' ||
            config?.dashboardType === 'newsletter'
            ? '100%'
            : '110%'
        )
        .attr(
          'height',
          config?.dashboardType === 'people' ||
            config?.dashboardType === 'storyAnalysis' ||
            config?.dashboardType === 'overview' ||
            config?.dashboardType === 'grid-dashboard' ||
            config?.dashboardType === 'newsletter'
            ? '100%'
            : '110%'
        );

      // Single chart group
      const g = svgMerge.selectAll('g.chart-group').data([null]);
      const gEnter = g.enter().append('g').attr('class', 'chart-group');
      const gMerge = gEnter.merge(g);

      gMerge.attr(
        'transform',
        `translate(${containerWidth / 2},${containerHeight / 2})`
      );

      const pie = d3
        .pie()
        .value((d) => d.value)
        .sort(null);

      const arc = d3
        .arc()
        .innerRadius(radius * config.innerRadius)
        .outerRadius(radius * config.outerRadius);

      const secondaryArc = d3
        .arc()
        .innerRadius(radius * config.outerRadius)
        .outerRadius(radius * config.secondaryOuterRadius);

      // Efficiently handle primary slices
      const primaryData = pie(data);
      const primaryGroups = gMerge
        .selectAll('.primary-group')
        .data(primaryData, (d, i) => `primary-${i}`);

      // Handle primary groups enter
      const primaryGroupsEnter = primaryGroups
        .enter()
        .append('g')
        .attr('class', (d, i) => `primary-group primary-group-${i}`);

      // Add primary slices only on enter
      primaryGroupsEnter
        .append('path')
        .attr('class', (d, i) => `primary-slice primary-slice-${i}`);

      // Update primary slices
      const primaryGroupsMerge = primaryGroupsEnter.merge(primaryGroups);

      primaryGroupsMerge
        .select('path')
        .attr('d', arc)
        .attr('fill', (d, i) => colorPalette[i % colorPalette.length])
        .attr('stroke', 'white');

      // Handle primary labels
      primaryGroupsMerge.each(function (d, i) {
        const group = d3.select(this);
        const textRadius =
          (radius * (config.innerRadius + config.outerRadius)) / 2;

        // Remove existing labels before adding new ones
        group.selectAll('.primary-label').remove();

        renderSmartLabel(
          group,
          d,
          textRadius,
          d.data.label,
          i,
          config,
          arc,
          true
        );

        const parentData = {
          ...d.data,
          labelColor: colorPalette[i % colorPalette.length],
        };

        group.datum(parentData);
        setUpEvents(config, selection, `primary-group-${i}`);
      });

      // Efficiently handle secondary slices
      data.forEach((primary, i) => {
        const secondaryPie = d3
          .pie()
          .value(1)
          .startAngle(primaryData[i].startAngle)
          .endAngle(primaryData[i].endAngle);

        const secondaryData = primary.keyword
          .slice(0, config.renderKeyword || 4)
          .map((k) => ({
            keyword: k.keyword,
            formatted_keyword: k.formated_keyword,
            parentData: { ...primary, value: k?.article_count || 0 },
          }));

        const secondaryGroups = gMerge
          .selectAll(`.secondary-group-${i}`)
          .data(secondaryPie(secondaryData), (d, j) => `secondary-${i}-${j}`);

        const secondaryGroupsEnter = secondaryGroups
          .enter()
          .append('g')
          .attr(
            'class',
            (d, j) =>
              `secondary-group secondary-group-${i} secondary-group-${i}-${j}`
          );

        // Add secondary slices only on enter
        secondaryGroupsEnter
          .append('path')
          .attr(
            'class',
            (d, j) =>
              `secondary-slice secondary-slice-${i} secondary-slice-${i}-${j}`
          );

        const secondaryGroupsMerge =
          secondaryGroupsEnter.merge(secondaryGroups);

        // Update secondary slices
        secondaryGroupsMerge
          .select('path')
          .attr('d', secondaryArc)
          .attr('fill', colorPalette[i % colorPalette.length])
          .attr('fill-opacity', config.opacity)
          .attr('stroke', 'white');

        secondaryGroupsMerge.each(function (d, j) {
          const group = d3.select(this);
          const midRadius =
            (radius * config.outerRadius +
              radius * config.secondaryOuterRadius) /
            2;

          // Remove existing labels before adding new ones
          group.selectAll('.secondary-label').remove();

          renderSmartLabel(
            group,
            d,
            midRadius,
            d?.data?.formatted_keyword || d.data.keyword,
            `${i}-${j}`,
            config,
            secondaryArc,
            false
          );
          const parentData = {
            ...d.data.parentData,
            label: d.data.keyword,
            secondary_label: d?.data?.formatted_keyword,
            thresholdValue: d.data?.parentData?.value,
            isKeyword: true,
            labelColor: colorPalette[i % colorPalette.length],
          };
          group.datum(parentData);
          setUpEvents(config, selection, `secondary-group-${i}-${j}`);
        });
      });
    });
  }

  chart.config = function (value) {
    if (!arguments.length) return config;
    config = { ...config, ...value };
    return chart;
  };

  return chart;
};
