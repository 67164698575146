import {
  format,
  addDays,
  addMonths,
  addYears,
  startOfMonth,
  endOfMonth,
  getDaysInMonth,
  parse,
  isWithinInterval,
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import isAfter from 'date-fns/isAfter';

import { mediaTabData } from '../hooks/data/chartData';
import { blueColorGradients } from './graph-colors';

function convertToHHMM(timeString) {
  const timeComponents = timeString.split(':');
  const hours = parseInt(timeComponents[0], 10);
  const minutes = parseInt(timeComponents[1], 10);
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
  return formattedTime;
}

export function wrapInDoubleQuotes(str) {
  // Remove existing quotes if present
  const unwrapped = str.replace(/^["']|["']$/g, '');
  // Wrap the string in double quotes
  return `"${unwrapped}"`;
}

export function isCombinedDateTimeGreaterThanCurrent(
  dateString,
  timeString,
  timezone
) {
  const parseTimeString = convertToHHMM(timeString ?? '');
  const combinedDateTime = parse(
    `${dateString} ${parseTimeString}`,
    'MM/dd/yyyy HH:mm',
    new Date()
  );

  // Convert the zoned time to UTC
  const combinedDateTimeInUTC = zonedTimeToUtc(combinedDateTime, timezone);

  // Convert the combined date and time to the given timezone
  const combinedDateTimeInZone = utcToZonedTime(
    combinedDateTimeInUTC,
    timezone
  );

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Get the current time in the specified timezone, then convert to UTC
  const currentTime = new Date();
  // const currentTimeInZone = utcToZonedTime(new Date(), timezone);
  const currentTimeInUTC = zonedTimeToUtc(currentTime, localTimezone);

  // Convert UTC time to the target timezone
  const targetZonedTime = utcToZonedTime(currentTimeInUTC, timezone);

  // Compare the combined date and time in UTC to the current time in UTC
  return isAfter(combinedDateTimeInZone, targetZonedTime);
}

export function convertDate(dateString) {
  const [year, month, day] = dateString.split('-');

  const paddedMonth = month?.padStart(2, '0');
  const paddedDay = day?.padStart(2, '0');
  const paddedYear = year?.padStart(4, '0');

  return paddedDay === undefined ||
    paddedMonth === undefined ||
    paddedYear === undefined
    ? ''
    : `${paddedMonth}/${paddedDay}/${paddedYear}`;
}

export const getDateRange = (selectedValue) => {
  const inputDate = new Date();
  switch (selectedValue) {
    case 'today':
      return {
        startDate: format(inputDate, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };

    case 'yesterday': {
      const yesterday = addDays(inputDate, -1);
      return {
        startDate: format(yesterday, 'yyyy-MM-dd'),
        endDate: format(yesterday, 'yyyy-MM-dd'),
      };
    }

    case 'last_24_hours': {
      const startDate = addDays(inputDate, -1);
      return {
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };
    }

    case 'last_1_week': {
      const lastWeek = addDays(inputDate, -7);

      return {
        startDate: format(lastWeek, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };
    }

    case 'last_1_month': {
      const lastMonth = addMonths(inputDate, -1);
      return {
        startDate: format(lastMonth, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };
    }

    case 'last_3_months': {
      const last3Months = addMonths(inputDate, -3);
      return {
        startDate: format(last3Months, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };
    }

    case 'last_6_months': {
      const last6Months = addMonths(inputDate, -6);
      return {
        startDate: format(last6Months, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };
    }

    case 'last_1_year': {
      const lastYear = addYears(inputDate, -1);
      return {
        startDate: format(lastYear, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };
    }

    case 'this_week': {
      const currentDay = new Date();
      const currentDate = new Date()?.getDay();
      const startOfWeek = new Date(
        currentDay.getFullYear(),
        currentDay.getMonth(),
        currentDay.getDate() - currentDate
      );
      const endOfWeek = new Date(
        startOfWeek.getFullYear(),
        startOfWeek.getMonth(),
        startOfWeek.getDate() + 6
      );

      return {
        startDate: format(startOfWeek, 'yyyy-MM-dd'),
        endDate: format(endOfWeek, 'yyyy-MM-dd'),
      };
    }
    case 'this_month': {
      const startofMonth = startOfMonth(new Date());
      const endofMonth = endOfMonth(new Date());
      return {
        startDate: format(startofMonth, 'yyyy-MM-dd'),
        endDate: format(endofMonth, 'yyyy-MM-dd'),
      };
    }

    default: {
      const lastWeek = addDays(inputDate, -7);
      return {
        startDate: format(lastWeek, 'yyyy-MM-dd'),
        endDate: format(inputDate, 'yyyy-MM-dd'),
      };
    }
  }
};

export const getDateMonthFirstLastDate = (dateString) => {
  const firstDayOfMonth = dateString + '-01';

  // Get the last day of the month
  const lastDayOfMonth =
    dateString + `-${getDaysInMonth(new Date(dateString + '-01'))}`;
  return { firstDayOfMonth, lastDayOfMonth };
};

export const convertTimeHours = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  minutes = minutes || '00';
  hours = hours?.length > 1 ? hours : `0${hours}`;

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export const getStartAndEndDate = (selectedDate, searchFilters) => {
  const dateFormat = identifyDateFormat(selectedDate);
  if (dateFormat === 'Hour') {
    const date = selectedDate?.slice(0, 11);
    const hours = selectedDate?.slice(11);
    const convertHours = convertTimeHours(hours);
    const updatedSelectedText = `${date?.trim()}T${convertHours}`;
    const updatedEndDate = updatedSelectedText.slice(0, -2) + '59';
    return {
      start_date: updatedSelectedText,
      end_date: updatedEndDate,
    };
  } else if (dateFormat === 'Month') {
    const monthRange = getDateMonthFirstLastDate(selectedDate);
    const filterData = {
      startDate: searchFilters?.start_date,
      endDate: searchFilters?.end_date,
    };
    const { firstDayOfMonth, lastDayOfMonth } = filterDates(
      filterData,
      monthRange
    );
    return {
      start_date: firstDayOfMonth,
      end_date: lastDayOfMonth,
    };
  } else if (dateFormat === 'Day') {
    return {
      start_date: selectedDate,
      end_date: selectedDate,
    };
  }
};

export function searchQueryForGraph(
  widget,
  selectedText,
  rawData,
  otherInfo,
  searchFilters
) {
  const defaultFilter = {
    save_recent_search: false,
  };

  switch (widget) {
    case 'Geographical Breakdown':
      return {
        ...defaultFilter,
        // state: rawData,
        countries: [otherInfo?.d?.properties?.iso_a2.toLowerCase()],
      };
    case 'Data Sources':
      return {
        ...defaultFilter,
        media_types: [
          [...traditional, ...social].find(
            (media) => media?.toLowerCase() === selectedText.toLowerCase()
          ),
        ],
      };
    case 'Sentiment':
      return {
        ...defaultFilter,
        sentiments: [
          selectedText === 'Positive'
            ? 'POS'
            : selectedText === 'Negative'
            ? 'NEG'
            : 'NEU',
        ],
      };
    case 'Popular Websites': {
      const traditionalMediaTypes =
        getSelectedTypesForTraditionalGraph(searchFilters);
      return {
        ...defaultFilter,
        source_includes: [rawData?.label],
        media_types: traditionalMediaTypes?.mediaTypes,
      };
    }

    case 'Popular Social Channels': {
      const socialMediaTypes = getSelectedTypesForSocialGraph(searchFilters);
      return {
        ...defaultFilter,
        source_includes: [rawData?.label],
        media_types: socialMediaTypes?.mediaTypes,
      };
    }

    case 'Results Over Time': {
      const dateFormat = identifyDateFormat(
        otherInfo?.d?.data?.date || otherInfo?.d?.date
      );
      const selectedMediaTrend = (
        otherInfo?.d?.type || otherInfo?.d?.labelText
      )?.toLowerCase();

      let mediaTypes;
      if (selectedMediaTrend === 'social') {
        mediaTypes = getSelectedTypesForSocialGraph(searchFilters);
      } else if (selectedMediaTrend === 'traditional') {
        mediaTypes = getSelectedTypesForTraditionalGraph(searchFilters);
      }

      if (dateFormat === 'Hour') {
        const date = otherInfo?.d?.data?.date?.slice(0, 11);
        const hours = otherInfo?.d?.data?.date?.slice(11);
        const convertHours = convertTimeHours(hours);
        const updatedSelectedText = `${date?.trim()}T${convertHours}`;
        const updatedEndDate = updatedSelectedText.slice(0, -2) + '59';
        return {
          ...defaultFilter,
          start_date: updatedSelectedText,
          end_date: updatedEndDate,
          media_types: mediaTypes?.mediaTypes,
        };
      } else if (dateFormat === 'Month') {
        const monthRange = getDateMonthFirstLastDate(otherInfo?.d?.data?.date);
        const filterData = {
          startDate: searchFilters?.start_date,
          endDate: searchFilters?.end_date,
        };
        const { firstDayOfMonth, lastDayOfMonth } = filterDates(
          filterData,
          monthRange
        );
        return {
          ...defaultFilter,
          start_date: firstDayOfMonth,
          end_date: lastDayOfMonth,
          media_types: mediaTypes?.mediaTypes,
        };
      } else if (dateFormat === 'Day') {
        return {
          ...defaultFilter,
          start_date: otherInfo?.d?.data?.date || otherInfo?.d?.date,
          end_date: otherInfo?.d?.data?.date || otherInfo?.d?.date,
          media_types: mediaTypes?.mediaTypes,
        };
      }
      break;
    }
    case 'Word Cloud':
      return {
        ...defaultFilter,
        wordcloud: selectedText,
        type: otherInfo?.d?.type,
      };
    case 'Popular Topics': {
      let themeKeywords;
      if (otherInfo?.d?.isKeyword) {
        themeKeywords = [otherInfo?.d?.label];
      } else {
        themeKeywords = themeKeywords = otherInfo?.d?.keyword?.map(
          (item) => item.keyword
        );
      }
      return {
        ...defaultFilter,
        theme: themeKeywords,
      };
    }
    case 'Top Media Contributors':
      return {
        ...defaultFilter,
        author: selectedText,
        author_id: rawData?.author_id,
      };

    case 'Media Outlet Breakdown': {
      const label = otherInfo?.d?.labelText;
      let mediaType = null;
      if (
        [...traditional, ...social].find(
          (mediaLabel) =>
            selectedText?.toLowerCase() === mediaLabel?.toLowerCase()
        )
      ) {
        mediaType = [
          [...traditional, ...social].find(
            (mediaLabel) =>
              selectedText?.toLowerCase() === mediaLabel?.toLowerCase()
          ),
        ];
      } else if (label === 'social') {
        mediaType = social;
      } else if (label === 'traditional') {
        mediaType = traditional;
      } else {
        mediaType = [label];
      }
      return {
        ...defaultFilter,
        media_types: mediaType,
        sub_media: otherInfo?.d?.label === 'Traditional' ? '' : label,
      };
    }
    case 'Top Hashtags':
      return {
        ...defaultFilter,
        hashtag: selectedText.replace(/^#/, ''),
      };
    case 'Top Subreddits':
      return {
        ...defaultFilter,
        subreddit_name: selectedText,
      };
    case 'Top Influencers':
      return {
        ...defaultFilter,
        author: selectedText,
        author_id: rawData?.author_id,
      };
    case 'Syndication': {
      return {
        ...defaultFilter,
        syndication_article_title: rawData?.syndication_title,
        syndication_article_id: rawData?.articleId,
      };
    }
    default:
      return {
        save_recent_search: true,
      };
  }
}

export function filterDates(filterData, monthRange) {
  const { startDate, endDate } = filterData;
  const { firstDayOfMonth, lastDayOfMonth } = monthRange;

  const startOfRange = parse(startDate, 'yyyy-MM-dd', new Date());
  const endOfRange = parse(endDate, 'yyyy-MM-dd', new Date());
  const startOfMonthDate = parse(firstDayOfMonth, 'yyyy-MM-dd', new Date());
  const endOfMonthDate = parse(lastDayOfMonth, 'yyyy-MM-dd', new Date());

  // Check if the entire month range is within the filter range
  if (
    isWithinInterval(startOfMonthDate, {
      start: startOfRange,
      end: endOfRange,
    }) &&
    isWithinInterval(endOfMonthDate, { start: startOfRange, end: endOfRange })
  ) {
    return monthRange; // Entire month range is within filter range
  }

  // Check if start of the month is within the filter range
  const minDate = isWithinInterval(startOfMonthDate, {
    start: startOfRange,
    end: endOfRange,
  })
    ? startOfMonthDate
    : startOfRange;

  // Check if end of the month is within the filter range
  const maxDate = isWithinInterval(endOfMonthDate, {
    start: startOfRange,
    end: endOfRange,
  })
    ? endOfMonthDate
    : endOfRange;

  return {
    firstDayOfMonth: format(minDate, 'yyyy-MM-dd'),
    lastDayOfMonth: format(maxDate, 'yyyy-MM-dd'),
  };
}

export function identifyDateFormat(dateString) {
  if (dateString === undefined) {
    return;
  }
  if (dateString?.includes('AM') || dateString?.includes('PM')) {
    return 'Hour';
  }

  if (/\d{4}-\d{2}-\d{2}/.test(dateString)) {
    return 'Day';
  }

  if (/\d{4}-\d{2}/.test(dateString)) {
    return 'Month';
  }
  throw Error('Unkown format');
}

export function removeQuotesFromObjectKeys(obj) {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const newKey = key.replace(/^"(.*)"$/, '$1');
    newObj[newKey] = obj[key];
  });

  return newObj;
}

export const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
export const traditional = ['Online', 'Print'];

export const sentiment = ['Positive', 'Negative', 'Neutral'];

export function getDashboardSearchQuery(
  widget,
  selectedText,
  rawData,
  otherInfo,
  searchFilters,
  tabsData
) {
  const defaultFilter = {
    save_recent_search: false,
  };
  switch (otherInfo?.uniqueId) {
    case 'brand_engagement_metrics': {
      return {
        ...defaultFilter,
        media_types: rawData?.label,
      };
    }
    case 'people_popular_topic_themes': {
      let themeKeywords;
      if (otherInfo?.d?.isKeyword) {
        themeKeywords = [otherInfo?.d?.label];
      } else {
        themeKeywords = themeKeywords = otherInfo?.d?.keyword?.map(
          (item) => item.keyword
        );
      }
      return {
        ...defaultFilter,
        theme: themeKeywords,
      };
    }
    case 'story_analysis_top_themes': {
      let themeKeywords;
      if (otherInfo?.d?.isKeyword) {
        themeKeywords = [otherInfo?.d?.label];
      } else {
        themeKeywords = themeKeywords = otherInfo?.d?.keyword?.map(
          (item) => item.keyword
        );
      }
      return {
        ...defaultFilter,
        theme: themeKeywords,
      };
    }
    case 'brand_seasonal_trends': {
      const social = [
        'X (Twitter)',
        'Blogs',
        'Forums',
        'Reviews',
        'Reddit',
        'YouTube',
      ];
      const traditional = ['Online', 'Print'];

      let media_types;
      if (!searchFilters?.media_types) {
        media_types = [...social];
      } else {
        media_types = searchFilters.media_types.filter(
          (type) => !traditional.includes(type)
        );
      }

      return {
        ...defaultFilter,
        media_types,
      };
    }
    case 'sentiment_over_time_social':
    case 'coverage_over_time_social':
    case 'reach_over_time_social':
    case 'people_coverage_over_time_social':
    case 'industry_coverage_over_time_social': {
      const filters = getStartAndEndDate(
        rawData?.date || otherInfo?.d?.rawData?.date,
        searchFilters
      );
      if (searchFilters?.media_types) {
        return {
          ...defaultFilter,
          ...filters,
          media_types: searchFilters?.media_types?.filter((x) =>
            social.includes(x)
          ),
        };
      }
      return {
        ...defaultFilter,
        ...filters,
        media_types: social,
      };
    }

    case 'sentiment_analysis':
      return {
        ...defaultFilter,
        sentiments: [
          selectedText === 'Positive'
            ? 'POS'
            : selectedText === 'Negative'
            ? 'NEG'
            : 'NEU',
        ],
      };
    case 'reach_over_time':
    case 'coverage_over_time':
    case 'sentiment_over_time':
    case 'people_coverage_over_time':
    case 'industry_coverage_over_time': {
      const filters = getStartAndEndDate(rawData?.date, searchFilters);
      if (searchFilters?.media_types) {
        return {
          ...defaultFilter,
          ...filters,
          media_types: searchFilters?.media_types?.filter((x) =>
            traditional.includes(x)
          ),
        };
      }
      return {
        ...defaultFilter,
        ...filters,
        media_types: traditional,
      };
    }
    case 'competitive_coverage_over_time':
    case 'competitive_reach_over_time': {
      const filters = getStartAndEndDate(rawData?.date, searchFilters);
      const competitionKeywords = Array.isArray(
        searchFilters?.competition_keywords
      )
        ? searchFilters.competition_keywords
        : [];
      const brandKeywords = Array.isArray(searchFilters?.brand_keywords)
        ? searchFilters.brand_keywords
        : [];
      return {
        ...defaultFilter,
        ...filters,
        brand_keywords: brandKeywords,
        // brand_keywords: [],
        // competition_keywords: [...competitionKeywords, ...brandKeywords],
        media_types: searchFilters?.media_types
          ? searchFilters?.media_types?.filter((x) => traditional.includes(x))
          : traditional,
      };
    }
    case 'competitive_coverage_over_time_social':
    case 'competitive_reach_over_time_social': {
      const filters = getStartAndEndDate(rawData?.date, searchFilters);
      const competitionKeywords = Array.isArray(
        searchFilters?.competition_keywords
      )
        ? searchFilters.competition_keywords
        : [];
      const brandKeywords = Array.isArray(searchFilters?.brand_keywords)
        ? searchFilters.brand_keywords
        : [];
      return {
        ...defaultFilter,
        ...filters,
        brand_keywords: brandKeywords,
        // brand_keywords: [],
        // competition_keywords: [...competitionKeywords, ...brandKeywords],
        media_types: searchFilters?.media_types
          ? searchFilters?.media_types?.filter((x) => social.includes(x))
          : social,
      };
    }

    case 'media_type': {
      const filters = getStartAndEndDate(rawData?.date, searchFilters);
      const mediaType = otherInfo?.d?.rawData?.mediaType;
      return {
        ...defaultFilter,
        ...filters,
        ...(mediaType ? { media_types: [mediaType] } : {}),
      };
    }
    case 'article_sentiment': {
      return {
        ...defaultFilter,
        // competition_keywords: [rawData?.label],
        brand_keywords: tabsData?.filter(
          (tabValues) => tabValues?.name === rawData?.label
        )?.[0]?.keywords,
        sentiments: [
          otherInfo?.d?.labelText === 'Positive'
            ? 'POS'
            : otherInfo?.d?.labelText === 'Negative'
            ? 'NEG'
            : 'NEU',
        ],
        aspect_sentiment: true,
      };
    }
    case 'sov': {
      return {
        ...defaultFilter,
        // competition_keywords: [rawData?.label],
        brand_keywords: tabsData?.filter(
          (tabValues) => tabValues?.name === rawData?.label
        )?.[0]?.keywords,
      };
    }
    case 'breakdown_by_media_type': {
      return {
        ...defaultFilter,
        // competition_keywords: [otherInfo?.d?.labelText],
        // brand_keywords: [otherInfo?.d?.labelText],
        brand_keywords: tabsData?.filter(
          (tabValues) => tabValues?.name === otherInfo?.d?.labelText
        )?.[0]?.keywords,
        media_types: [
          [...traditional, ...social].find(
            (media) => media?.toLowerCase() === selectedText.toLowerCase()
          ),
        ],
      };
    }
    case 'coverage_by_journalist': {
      const labelText = otherInfo.d.labelText; // Extract labelText from otherInfo
      const authorIdKey =
        labelText.replace(/[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g, '') +
        '_author_id';
      otherInfo.authorId = rawData[authorIdKey];
      return {
        ...defaultFilter,
        // competition_keywords: [rawData?.key || otherInfo?.d?.label],
        // brand_keywords: [rawData?.key || otherInfo?.d?.label],
        brand_keywords: tabsData?.filter(
          (tabValues) =>
            tabValues?.name === otherInfo?.d?.label ||
            tabValues?.name === rawData?.key
        )?.[0]?.keywords,
        author: otherInfo?.d?.labelText || rawData?.label,
        author_id: otherInfo?.authorId || rawData?.author_id,
      };
    }

    case 'industry_top_influencers': {
      return {
        ...defaultFilter,
        author: otherInfo?.authorName,
        author_id: otherInfo?.author_id,
        media_types: searchFilters?.media_types
          ? searchFilters?.media_types?.filter((x) => social.includes(x))
          : social,
      };
    }

    case 'coverage_by_source': {
      return {
        ...defaultFilter,
        // competition_keywords: [
        //   rawData?.key ||
        //     otherInfo?.d?.parentData?.label ||
        //     otherInfo?.d?.label,
        // ],
        // brand_keywords: [
        //   rawData?.key ||
        //     otherInfo?.d?.parentData?.label ||
        //     otherInfo?.d?.label,
        // ],
        brand_keywords: tabsData?.filter(
          (tabValues) =>
            tabValues?.name === otherInfo?.d?.label ||
            tabValues?.name === otherInfo?.d?.parentData?.label ||
            tabValues?.name === rawData?.key
        )?.[0]?.keywords,
        source_includes: [
          otherInfo?.d?.labelText ||
            rawData?.label ||
            otherInfo?.d?.parentData?.parentData?.label,
        ],
      };
    }

    case 'people_top_source_by_sentiment': {
      return {
        ...defaultFilter,
        source_includes: [selectedText],
        sentiments: [
          otherInfo?.d?.labelText === 'Positive'
            ? 'POS'
            : otherInfo?.d?.labelText === 'Negative'
            ? 'NEG'
            : 'NEU',
        ],
      };
    }

    case 'people_top_journalist_by_sentiment':
    case 'people_top_journalist_by_sentiment_social': {
      return {
        ...defaultFilter,
        sentiments: [
          otherInfo?.d?.labelText === 'Positive'
            ? 'POS'
            : otherInfo?.d?.labelText === 'Negative'
            ? 'NEG'
            : 'NEU',
        ],
        author: rawData?.label,
        author_id: rawData?.author_id,
      };
    }

    case 'people_popular_topics': {
      return {
        ...defaultFilter,
        wordcloud: rawData?.label,
        type: otherInfo?.d?.type,
      };
    }

    case 'people_media_type': {
      const filters = getStartAndEndDate(rawData?.date, searchFilters);
      const mediaType = otherInfo?.d?.rawData?.mediaType;
      return {
        ...defaultFilter,
        ...(mediaType ? { media_types: [mediaType] } : {}),
        ...filters,
      };
    }
    case 'industry_sentiment_analysis':
      return {
        ...defaultFilter,
        sentiments: [
          selectedText === 'Positive'
            ? 'POS'
            : selectedText === 'Negative'
            ? 'NEG'
            : 'NEU',
        ],
      };
    case 'industry_coverage_by_source': {
      const filters = getStartAndEndDate(
        otherInfo?.d?.date || otherInfo?.d?.rawData?.date,
        searchFilters
      );
      return {
        ...defaultFilter,
        ...filters,
      };
    }
    case 'industry_companies_mentioned':
    case 'brand_companies_mentioned':
      return {
        ...defaultFilter,
        company: otherInfo?.d?.label,
      };
    case 'brand_social_word_cloud': {
      const social = [
        'X (Twitter)',
        'Blogs',
        'Forums',
        'Reviews',
        'Reddit',
        'YouTube',
      ];
      const traditional = ['Online', 'Print'];

      let media_types;
      if (!searchFilters?.media_types) {
        media_types = [...social];
      } else {
        media_types = searchFilters.media_types.filter(
          (type) => !traditional.includes(type)
        );
      }

      return {
        ...defaultFilter,
        media_types,
        wordcloud: otherInfo?.d?.label,
        type: otherInfo?.d?.type,
      };
    }
    case 'industry_coverage_by_journalist': {
      return {
        ...defaultFilter,
        author: rawData?.label,
        author_id: rawData?.author_id,
      };
    }
    case 'industry_coverage_by_top_publications': {
      return {
        ...defaultFilter,
        source_includes: rawData?.label,
      };
    }

    case 'brand_result_over_time': {
      const filters = getStartAndEndDate(otherInfo?.d?.date, searchFilters);
      return {
        ...defaultFilter,
        ...filters,
      };
    }
    case 'Syndication': {
      return {
        ...defaultFilter,
        syndication_article_title: rawData?.syndication_title,
        syndication_article_id: rawData?.articleId,
      };
    }

    case 'story_analysis_geographical_breakdown': {
      return {
        ...defaultFilter,
        countries: [otherInfo?.d?.properties?.iso_a2.toLowerCase()],
      };
    }
    case 'story_analysis_media_channels': {
      return {
        ...defaultFilter,
        media_types: [
          [...traditional, ...social].find(
            (media) => media?.toLowerCase() === selectedText.toLowerCase()
          ),
        ],
      };
    }
    case 'story_analysis_word_could': {
      return {
        ...defaultFilter,
        wordcloud: selectedText,
      };
    }
    case 'story_analysis_top_sources': {
      return {
        ...defaultFilter,
        source_includes: [otherInfo?.d?.data?.label],
      };
    }
    case 'story_analysis_outlet_breakdown': {
      const label = otherInfo?.d?.labelText;
      let mediaType = null;
      if (
        [...traditional, ...social].find(
          (mediaLabel) =>
            selectedText?.toLowerCase() === mediaLabel?.toLowerCase()
        )
      ) {
        mediaType = [
          [...traditional, ...social].find(
            (mediaLabel) =>
              selectedText?.toLowerCase() === mediaLabel?.toLowerCase()
          ),
        ];
      } else if (label === 'social') {
        mediaType = social;
      } else if (label === 'traditional') {
        mediaType = traditional;
      } else {
        mediaType = [label];
      }
      return {
        ...defaultFilter,
        media_types: mediaType,
        sub_media: otherInfo?.d?.label === 'Traditional' ? '' : label,
      };
    }

    case 'top_influencers': {
      return {
        ...defaultFilter,
        author: selectedText,
        author_id: rawData?.author_id,
      };
    }
    case 'story_analysis_sentiment': {
      return {
        ...defaultFilter,
        sentiments: [
          selectedText === 'Positive'
            ? 'POS'
            : selectedText === 'Negative'
            ? 'NEG'
            : 'NEU',
        ],
      };
    }

    case 'story_analysis_results_over_time': {
      const dateFormat = identifyDateFormat(
        otherInfo?.d?.data?.date || otherInfo?.d?.date
      );
      if (dateFormat === 'Hour') {
        const date = otherInfo?.d?.data?.date?.slice(0, 11);
        const hours = otherInfo?.d?.data?.date?.slice(11);
        const convertHours = convertTimeHours(hours);
        const updatedSelectedText = `${date?.trim()}T${convertHours}`;
        const updatedEndDate = updatedSelectedText.slice(0, -2) + '59';
        return {
          ...defaultFilter,
          start_date: updatedSelectedText,
          end_date: updatedEndDate,
        };
      } else if (dateFormat === 'Month') {
        const monthRange = getDateMonthFirstLastDate(otherInfo?.d?.data?.date);
        const filterData = {
          startDate: searchFilters?.start_date,
          endDate: searchFilters?.end_date,
        };
        const { firstDayOfMonth, lastDayOfMonth } = filterDates(
          filterData,
          monthRange
        );
        return {
          ...defaultFilter,
          start_date: firstDayOfMonth,
          end_date: lastDayOfMonth,
        };
      } else if (dateFormat === 'Day') {
        return {
          ...defaultFilter,
          start_date: otherInfo?.d?.data?.date || otherInfo?.d?.date,
          end_date: otherInfo?.d?.data?.date || otherInfo?.d?.date,
        };
      }
      break;
    }

    default:
      return {
        save_recent_search: true,
      };
  }
}

export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const combineImagesVertical = (imageUrls) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Load the first image to get its dimensions
    const firstImage = new Image();
    firstImage.onload = () => {
      const aspectRatios = [firstImage.width / firstImage.height];

      // Load the rest of the images and get their aspect ratios
      const promises = imageUrls.slice(1).map((imageUrl) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            aspectRatios.push(aspectRatio);
            resolve();
          };
          img.onerror = (error) => {
            reject(new Error(`Failed to load image: ${imageUrl}. ${error}`));
          };
          img.src = imageUrl;
        });
      });

      // Wait for all images to load and get their aspect ratios
      Promise.all([firstImage].concat(promises))
        .then(() => {
          // Calculate the total height needed for vertical stacking
          const totalHeight = aspectRatios.reduce(
            (sum, ratio) => sum + firstImage.width / ratio,
            0
          );

          // Set canvas dimensions
          canvas.width = firstImage.width;
          canvas.height = totalHeight;

          // Draw each image onto the canvas with vertical stacking
          let yOffset = 0;
          for (let i = 0; i < imageUrls.length; i++) {
            const imageUrl = imageUrls[i];
            const img = new Image();
            // eslint-disable-next-line no-loop-func
            img.onload = () => {
              const aspectRatio = img.width / img.height;
              const height = firstImage.width / aspectRatio;

              // Draw the image onto the canvas
              ctx.drawImage(img, 0, yOffset, firstImage.width, height);

              // Update the yOffset for the next image
              yOffset += height;

              // If this is the last image, resolve the promise
              if (i === imageUrls.length - 1) {
                resolve(canvas.toDataURL('image/png'));
              }
            };
            img.src = imageUrl;
          }
        })
        .catch((error) => {
          reject(error);
        });
    };
    firstImage.src = imageUrls[0];
  });
};

export const combineImagesWithLogo = async (
  imageUrls,
  logoUrl,
  padding = 30
) => {
  // Combine images vertically with padding
  const combinedImage = await combineImagesVertical(imageUrls);

  // Load the combined image
  const img = new Image();
  img.src = combinedImage;

  await new Promise((resolve) => {
    img.onload = resolve;
  });

  // Load the logo image
  const logoImage = new Image();
  logoImage.src = logoUrl;

  await new Promise((resolve) => {
    logoImage.onload = resolve;
  });

  // Calculate dimensions for the canvas including padding
  const canvasWidth = img.width + 20 * padding;
  const canvasHeight = img.height + 20 * padding;

  // Create a new canvas for combining with the logo
  const canvas = document.createElement('canvas');
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const ctx = canvas.getContext('2d');

  // Draw a white background
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  // Draw the logo in the top-left corner with padding
  ctx.drawImage(logoImage, padding, padding, logoImage.width, logoImage.height);

  // Draw the combined image below and to the right of the logo
  ctx.drawImage(
    img,
    padding,
    logoImage.height + 5 * padding,
    img.width,
    img.height
  );

  // Convert the combined image with the logo to a data URL
  const combinedImageWithLogoDataURL = canvas.toDataURL('image/png');
  return combinedImageWithLogoDataURL;
};

export const dataURLtoBlob = (dataUrl) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export function toCamelCase(inputString) {
  if (!inputString) return '';
  return inputString;
}

export function replaceOperators(sentence) {
  if (!sentence) {
    return '';
  }
  let stack = [];
  let replacedSentence = '';

  let characters = [];
  let doublQuoteString = [];

  for (let i = 0; i < sentence.length; i++) {
    const currentChar = sentence[i];

    if (currentChar === '"') {
      if (stack.length === 0) {
        stack = [i];
      } else {
        replacedSentence += replaceOperatorsInSentence(characters.join(''));
        replacedSentence += doublQuoteString.join('') + '"';
        characters = [];
        doublQuoteString = [];
        stack = [];
      }
    }

    if (stack.length === 0 && currentChar !== '"') {
      // Replace operators outside of double quotes
      characters.push(currentChar);
    } else if (stack.length > 0) {
      doublQuoteString.push(currentChar);
    }
  }

  return (
    replacedSentence +
    replaceOperatorsInSentence(characters.join('')) +
    doublQuoteString.join('')
  );
}

function replaceOperatorsInSentence(sentence) {
  const replacedSentence = sentence
    ?.replace(/\bAnd\b/gi, 'AND')
    ?.replace(/\bOR\b/gi, 'OR')
    ?.replace(/\bNOT\b/gi, 'NOT');

  return replacedSentence;
}

export function checkMismatchedQuotes(sentence) {
  const stack = [];

  for (const char of sentence) {
    if (char === '"') {
      if (stack.length > 0 && stack[stack.length - 1] === '"') {
        stack.pop();
      } else {
        stack.push(char);
      }
    }
  }
  return stack.length === 0;
}
export function isCorrectParenthesisOrder(sentence) {
  const stack = [];

  for (const char of sentence) {
    if (char === '(') {
      stack.push('(');
    } else if (char === ')') {
      if (stack.length === 0 || stack.pop() !== '(') {
        return false; // Closing parenthesis without a matching opening parenthesis
      }
    }
  }

  // Check if there are any unmatched opening parentheses
  return stack.length === 0;
}

export const logicalQueries = [
  // Queries that return false
  'a AND b OR c',
  'a OR b AND c',
  '(a AND b) OR (c AND d)',
  '(a OR b) AND (c OR d)',
  'a AND (b OR c) AND d',
  'a OR (b AND c) OR d',
  '(a AND b) OR c AND d',
  '(a OR b) AND c OR d',
  'a AND b OR (c AND d)',
  'a OR b AND (c OR d)',
  'a AND (b OR c AND d)',
  'a OR (b AND c OR d)',
  '(a AND b OR c) AND d',
  '(a OR b AND c) OR d',
  'a AND b OR c AND d',
  'a OR b AND c OR d',
  'a AND (b OR c) OR d',
  'a OR (b AND c) AND d',
  '(a AND b) OR (c OR d AND e)',
  '(a OR b) AND (c AND d OR e)',
  'a AND b OR c AND d OR e',
  'a OR b AND c OR d AND e',
  'a AND (b OR c AND d OR e)',
  'a OR (b AND c OR d AND e)',
  '(a AND b OR c) AND d OR e',
  '(a OR b AND c) OR d AND e',
  'a AND b OR (c AND d OR e)',
  'a OR b AND (c OR d AND e)',
  'a AND (b OR c) AND d OR e',
  'a OR (b AND c) OR d AND e',
  '(a AND b) OR (c OR d AND e OR f)',
  '(a OR b) AND (c AND d OR e AND f)',
  'a AND b OR c AND d OR e AND f',
  'a OR b AND c OR d AND e OR f',
  'a AND (b OR c AND d OR e AND f)',
  'a OR (b AND c OR d AND e OR f)',
  '(a AND b OR c) AND d OR e AND f',
  '(a OR b AND c) OR d AND e OR f',
  'a AND b OR (c AND d OR e AND f)',
  'a OR b AND (c OR d AND e OR f)',

  // Queries that return true
  'a AND b AND c',
  'a OR b OR c',
  '(a AND b) AND (c AND d)',
  '(a OR b) OR (c OR d)',
  'a AND (b AND c) AND d',
  'a OR (b OR c) OR d',
  '(a AND b) AND c AND d',
  '(a OR b) OR c OR d',
  'a AND b AND (c AND d)',
  'a OR b OR (c OR d)',
  'a AND (b AND c AND d)',
  'a OR (b OR c OR d)',
  '(a AND b AND c) AND d',
  '(a OR b OR c) OR d',
  'a AND b AND c AND d',
  'a OR b OR c OR d',
  'a AND (b AND c) OR d',
  'a OR (b OR c) AND d',
  '(a AND b) OR (c AND d)',
  '(a OR b) AND (c OR d)',
  'a AND b AND c AND d AND e',
  'a OR b OR c OR d OR e',
  'a AND (b AND c AND d AND e)',
  'a OR (b OR c OR d OR e)',
  '(a AND b AND c) AND d AND e',
  '(a OR b OR c) OR d OR e',
  'a AND b AND (c AND d AND e)',
  'a OR b OR (c OR d OR e)',
  'a AND (b AND c) AND d AND e',
  'a OR (b OR c) OR d OR e',
  '(a AND b AND c) AND (d AND e)',
  '(a OR b OR c) OR (d OR e)',
  'a AND b AND c AND d AND e AND f',
  'a OR b OR c OR d OR e OR f',
  'a AND (b AND c AND d AND e AND f)',
  'a OR (b OR c OR d OR e OR f)',
  '(a AND b AND c) AND d AND e AND f',
  '(a OR b OR c) OR d OR e OR f',
  'a AND b AND (c AND d AND e AND f)',
  'a OR b OR (c OR d OR e OR f)',
];

export const queries = [
  'abc AND ""',
  '(Subway OR Starbucks OR "ABC AND DEF" OR "McDonald\'s" OR McD OR "Dunkin\' Donuts" OR "Pizza Hut" OR "Burger King" OR Wendy\'s OR "Taco Bell" OR Domino\'s OR "Dairy Queen" OR "Little Caesars" OR KFC OR "Sonic Drive-In" OR "Papa John\'s" OR "Arby\'s")',
  '(Revlon OR "Revlon Professionnel" OR "Revlon Professional" OR #Revlon OR #Revlon Professionnel OR #Revlon Professional) NOT (shares OR stocks OR stockmarket OR holdings OR "growth analysis" OR "market analysis")',
  '("Neutrogena" AND ("Hydro Boost Hydrating Lip Treatment" OR "Hydro Boost Hydrating Lip Shine" OR "MoistureSmooth Color Stick" OR "Revitalizing Lip Balm SPF 20" OR "MoistureShine Lip Soother SPF 20" OR "Sheer Smooch Lip Oil" OR "Collagen Kiss Lip Mask" OR "Lip Show Lipstick" OR "Lip Show Lipstick Primer" OR "Norwegian Formula Lip Moisturizer with Sunscreen SPF 15" OR "Force of Nature Lip Palette by Kerry Washington - Limited Edition" OR "Healthy Lips Lip Plumping Serum"))',
  '("Prime Healthcare" AND ("patient safety" OR "price transparency" OR "cost efficiency" OR "real estate" OR "partnership" OR "joint venture" OR "residency programs" OR "quality" OR "hospital" OR "acquisition" OR "social responsibility" OR "kickbacks" OR "medicare" OR "initiative"))',
  '((sustainable OR planet OR "environment OR pollut" OR "chemical waste" OR climate OR "recycl*" OR "upcycl*" OR plastic OR biodegrad OR ethical OR #recycle OR #upcycle OR #sustainabl OR #environment OR #chemicalwaste OR "zero waste" OR ecofriendly OR "handmade" OR organic OR #ethicallymade OR ethical OR #ecoconcious OR #ethicalconsumption OR carbon OR "green house" OR "ethical labor" OR "animal cruelty" OR "cruelty free" OR "second hand" OR "local prod*" OR "transparent label" OR toxi* OR emissions OR "energy use" OR "ocean waste" OR "eco concious" OR biodiversity) AND (fashion* OR apparel OR cloth))',
  '("McDonald\'s" OR "Starbucks" OR "Chick-Fil-A" OR "Taco Bell" OR "Burger King" OR "Subway" OR "Wendy\'S" OR "Dunkin" OR "Domino\'S" OR "Panera Bread")',
  '(Comerica OR "Capital One" OR "Wells Fargo" OR "Huntington National Bank" OR UBS OR "Bank of America") AND ("Interest rate" OR "Credit Cards" OR Loan OR "Mortgage Loan")',
  '(Walmart OR Amazon OR Kroger OR Costco OR "Home Depot" OR Target OR "Lowe\'s" OR "Albertsons Companies" OR Albertsons OR Publix OR Aldi OR "Dollar General" OR "H-E-B" OR HEB OR Meijer OR Wakefern OR "Hy-Vee" OR "Wegman\'s" OR Wegmans OR "Giant Eagle" OR "Best Buy" OR "Macy\'s" OR Macys OR "7-Eleven" OR "Ross Stores" OR "BJ\'s Wholesale Club" OR "J.C. Penney Company" OR "JCPenney" OR)',
  '(Infovision OR Accenture OR "Happiest Minds" OR TCS OR CTS OR Infosys OR Wipro OR Genpact OR TEKSystems OR Prokarma OR Infogain OR Coforge OR Persistent OR "Tech Mahindra") AND (AI OR "Digital transformation" OR "Cloud Infrastructure" OR Blockchain OR IOT OR Metaverse OR "Data Engineering" OR "Enterprise Cybersecurity" OR "Risk Services")',
  '("Advair Diskus" OR "Advair HFA" OR "Anoro Ellipta" OR "Arnuity Ellipta" OR "Beconase" OR "Breo Ellipta" OR "Flolan" OR "Flovent Diskus" OR "Flovent HFA" OR "Imitrex Injection" OR "Imitrex Nasal Spray" OR "Imitrex Tablets" OR "Incruse Ellipta" OR "Krintafel" OR "Lamictal" OR "Lamictal ODT" OR "Lamictal XR" OR "Malarone" OR "Mepron" OR "Relenza" OR "Rythmol SR" OR "Serevent Diskus" OR "Soriatane" OR "Trelegy Ellipta" OR "Valtrex" OR "Ventolin HFA" OR "Wellbutrin SR" OR "Benlysta" OR "belimumab" OR "Epivir HBV" OR lamivudine OR Jemperli OR dostarlimab OR Nucala OR mepolizumab OR Ojjaara OR Momelotinib OR Zejula OR niraparib)',
  '((Walmart) AND (Retail OR Sustain))',
  '((("Staples, Inc" OR (Staples AND Quill) OR "TGT" OR "Target Corporation" OR "Walmart" OR "Office Depot" OR "ODP Corp" OR "OfficeMax") AND (office OR school OR furniture OR computer OR Printer OR hardware OR shipping OR Cleaning OR Marketing OR Retail OR Merger OR Acquisition OR "M&A" OR sustainability OR promotion)) NOT ("Company Stock" OR Stock OR Shares OR "Market Research" OR "Market Shares" OR NBA OR "Target center" OR Playoffs OR "Walmart Arkansas Music Pavilion" OR "Walmart AMP" OR "Amazon Prime" OR "Streaming service" OR streaming OR "Connected TV" OR NFL OR "Pelicans" OR "Ring Pet Portraits" OR "Atomic Minerals" OR "Target Price" OR "Russian forces" OR "Oil Protester" OR "TechTarget" OR Football OR basketball OR Sports OR "Russian Target" OR "head coach" OR "health target" OR "MSU target" OR "UF target" OR "electric vehicle" OR "Chinese smartphone" OR "Russian Market" OR "QuillBot" OR "La Quill"))',
  '((Alzheimer OR dementia OR "neurological disorders" OR "Alzheimer\'s Disease" OR "Alzheimer\'s" OR Neuroscience) AND (AI OR "Al tool" OR "Future Cognitive" OR "Artificial Intelligence" OR "multimodal Machine Learning" OR "diagnostic tool" OR "Predictive Modeling" OR "prediction model" OR "Predicts Future Cognitive"))',
  '("skincare" OR "beauty products" OR "cosmetics" OR "anti-aging" OR "fashion" OR "clothing" OR "accessories" OR "apparel") AND ("Sephora" OR "Ulta" OR "L\'Oréal" OR "Estée Lauder" OR "Zara" OR "H&M" OR "Nike" OR "Gucci" OR "Chanel" OR "Coty") NOT ("Gucci Mane" OR Stocks OR shares OR "market research" OR "Market report")',
  '("State Farm" OR "Progressive" OR Geico OR Allstate OR "Liberty Mutual") AND ("auto insurance" OR homeowners OR renters OR "business insurance" OR "commercial auto" OR "workers\' compensation" OR condo OR "umbrella insurance" OR Insurance OR "ProPack Commercial Package Policy" OR "ProEdge Businessowners Policy" OR "Commercial Excess AND Umbrella" OR "Boat AND Personal Watercraft" OR "Classic AND Collector Car Insurance" OR Motorcycle OR "ATV Insurance") NOT (Stocks OR Shares OR "Share price")',
  '(Alexion OR AstraZeneca OR Amgen OR Pfizer OR LogicBio) AND ("Rare Disease" OR "Rare Disease" OR "rare-disease")',
  '(Amazon OR "Amazon Prime" OR "Prime Video" OR Alexa OR "Amazon Devices" OR "Amazon International") NOT ("Amazon Stock" OR Stock OR "Market Report" OR "Market Research" OR "Peruvian Amazon" OR "Amazon Forest" OR "amazon deforestation" OR "Amazon rainforest")',
  '("Drug Pricing" OR "Drug price") AND (BMS OR AstraZeneca OR Novartis OR Sanofi OR GSK)',
  '(Vaccine OR RSV OR Rabbies OR "Covid-19" OR "Covid 19" OR Covid OR "Flu Vaccine") AND (GSK OR Sanofi OR Pfizer OR Novartis OR AstraZeneca OR BMS OR "J&J" OR "Johnson & Johnson" Sinovac OR Merck OR BioNTech OR Moderna OR Zhifei OR Novavax)',
  '(Pepsi OR "Coca Cola" OR Coke OR "Red Bull" OR "Tetra Pak" OR "Keurig Dr Pepper" OR "Dr Pepper" OR "Dr. Pepper")',
  '((Alzheimer OR dementia OR "neurological disorders" OR "Alzheimer\'s Disease" OR "Alzheimer\'s" OR Neuroscience) AND (AI OR "Al tool" OR "Future Cognitive" OR "Artificial Intelligence" OR "multimodal Machine Learning" OR "diagnostic tool" OR "Predictive Modeling" OR "prediction model" OR "Predicts Future Cognitive"))',
  '((hemodialysis OR "dialysis treatment" OR "renal dialysis" OR "kidney dialysis" OR "HD treatment" OR "hemodialysis therapy") OR ("dialysis machine" OR dialyzer OR "peritoneal dialysis") OR ("dialysis center" OR "home dialysis" OR "in-center dialysis" OR "dialysis schedule" OR "treatment side effects" OR "dialysis fatigue") OR ("#Hemodialysis" OR "#Dialysis Life" OR "#Kidney Failure" OR "#Kidney Disease" OR "#Dialysis Journey" OR "#Dialysis Warrior") OR ("Living with dialysis" OR "Hemodialysis treatment" OR "Dialysis side effects" OR "Dialysis experience" OR "Renal failure treatment" OR "Managing dialysis" OR "Kidney disease support" OR "Dialysis and diet" OR "Dialysis and lifestyle")) NOT (DOG OR CAT OR PET OR VET*)',
  '("car detailing" OR "auto detailing" OR "car appearance") AND Products',
  'Primary Hyperoxaluria AND "Alnylam Pharmaceuticals"',
  '("Chipotle Mexican Grill" OR chipotle) NOT ("Shares" OR "Stock" OR "Market Size" OR "Investment Advisors" OR "Shareholders" OR "ResearchAndMarkets.com" OR "MarketsandMarkets" OR "Stock Position" OR "Valuation" OR "Market Report" OR "Beats Estimates" OR "Stock Holdings" OR "CAGR" OR "Market Analysis" OR "Market to Grow" OR "Forecast" OR "Holdings" OR "Market Research" OR "Market Trends")',
  '("Metastatic breast cancer" OR "breast cancer" OR "breast carcinoma" OR "mammary cancer" OR "breast malignancy" OR "breast tumor" OR "breast neoplasm" OR "breast oncology" OR "triple negative breast cancer" OR TNBC OR "ductal carcinoma in situ" OR "lobular carcinoma in situ" OR "invasive ductal carcinoma" OR "invasive lobular carcinoma" OR "Ductal carcinoma in situ (DCIS)" OR "Invasive ductal carcinoma (IDC)" OR "Invasive lobular carcinoma (ILC)" OR "Triple-negative breast cancer" OR "HER2-positive breast cancer" OR "Hormone receptor-positive breast cancer" OR "Inflammatory breast cancer" OR "advanced breast cancer" OR "Stage IV breast cancer")',
  '("Luminal A" OR "HR+HER2-" OR "HR-positive" OR "HER2-negative" OR "Luminal B" OR "HR+HER2+" OR "HR-positive" OR "HER2-positive" OR "HR-HER2-" OR "HR HER2-negative" OR "Verzenio" OR "abemaciclib" OR "Cyramza" OR "ramucirumab" OR "Keytruda" OR "pembrolizumab" OR "Trazimera" OR "trastuzumab-qyyp" OR "Kisqali" OR "ribociclib" OR "Herceptin" OR "trastuzumab" OR "Perjeta" OR "pertuzumab" OR "Kadcyla" OR "trastuzumab emtansine" OR "Verzenio" OR "abemaciclib" OR "Cyramza" OR "ramucirumab" OR "KEYTRUDA" OR "pembrolizumab" OR "TRAZIMERA" OR "trastuzumab-qyyp" OR "Kisqali" OR "ribociclib" OR "Herceptin" OR "trastuzumab" OR "Perjeta" OR "pertuzumab" OR "Kadcyla" OR "trastuzumab emtansine") NOT (shares OR stock OR "company stock" OR "Market Report" OR "growth market")',
  'Kisunla OR donanemab OR "AntiAmyloid drug" OR "Anti-Amyloid drug" NOT (stocks OR "Market Research" OR "Market Report")',
  '(FDA OR "Eli Lilly\'s" OR "Eli Lilty") AND (Alzheimer OR Dementia)',
  '(Accounting OR Audit OR IFIAR OR PCAOB) AND KPMG',
];

/**
 * Function to check if logical operators are on the same level
 * @param {string} expression - The logical expression to check
 * @returns {boolean} - True if operators are on the same level, false otherwise
 */
export function checkLogicalOperators(expression) {
  // Helper function to parse the expression into a tree
  function parseExpression(expr) {
    const stack = [];
    let current = { type: 'root', children: [] };
    stack.push(current);
    let inQuotes = false;

    for (let i = 0; i < expr.length; i++) {
      const char = expr[i];

      if (char === '"') {
        inQuotes = !inQuotes;
        current.children.push({ type: 'literal', value: char });
      } else if (inQuotes) {
        current.children.push({ type: 'literal', value: char });
      } else if (char === '(') {
        const newNode = { type: 'group', children: [] };
        current.children.push(newNode);
        stack.push(newNode);
        current = newNode;
      } else if (char === ')') {
        stack.pop();
        current = stack[stack.length - 1];
      } else if (char.trim()) {
        // Check for multi-character operators
        if (
          expr.slice(i, i + 3).toUpperCase() === 'AND' &&
          !/[a-zA-Z]/.test(expr[i + 3])
        ) {
          current.children.push({ type: 'literal', value: 'AND' });
          i += 2; // Skip next 2 characters
        } else if (
          expr.slice(i, i + 2).toUpperCase() === 'OR' &&
          !/[a-zA-Z]/.test(expr[i + 2])
        ) {
          current.children.push({ type: 'literal', value: 'OR' });
          i += 1; // Skip next character
        } else {
          current.children.push({ type: 'literal', value: char });
        }
      }
    }

    return stack[0];
  }

  // Helper function to check if all operators at the same level are the same
  function checkSameLevel(node) {
    if (node.type === 'literal') {
      return true;
    }

    const operators = new Set();
    for (const child of node.children) {
      if (
        child.type === 'literal' &&
        (child.value === 'AND' || child.value === 'OR')
      ) {
        operators.add(child.value);
      }
    }

    if (operators.size > 1) {
      return false;
    }

    for (const child of node.children) {
      if (!checkSameLevel(child)) {
        return false;
      }
    }

    return true;
  }

  const parsedTree = parseExpression(expression);
  return checkSameLevel(parsedTree);
}

//
// ex: a and b and c or d or (a or b or (c or d and e))
// create a function that check if the logical operators are in the same level and return true or false
// we need to check the nested query is true or false, if it's false, return it. if it's true, check the above level.
// we can use tree logic to check the and or in same level

export function colorCodeObjects(arr) {
  // Extract values and their counts
  const counts = arr.map((obj) => obj.value);
  const uniqueCounts = [...new Set(counts)];

  // Sort unique counts in descending order
  uniqueCounts.sort((a, b) => b - a);

  // Get all color keys from blueColorGradients
  const gradientKeys = Object.keys(blueColorGradients);

  // Exclude the first three and last three colors
  const remainingColors = gradientKeys.slice(3, gradientKeys.length - 3);

  // Determine the last color in remainingColors
  const lastColor = remainingColors[remainingColors.length - 1];

  // Map each unique count to a color code from remaining colors or use the last color if no more colors are available
  const colorMap = {};
  uniqueCounts.forEach((count, index) => {
    const colorIndex = Math.min(index, remainingColors.length - 1); // Limit index to the length of remainingColors
    colorMap[count] = blueColorGradients[remainingColors[colorIndex]];
  });

  // Assign color codes to each object based on its value
  arr.forEach((obj) => {
    const value = obj.value;
    obj.color = colorMap[value] || blueColorGradients[lastColor]; // Use last color if no specific color is found
  });

  return arr;
}

export function addCountPrefix(number) {
  if (!number) {
    return '0';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  });

  if (number < 1000) {
    return Math.abs(formatter.format(number));
  }

  const suffixes = ['K', 'M', 'B', 'T'];
  const suffixNum = Math.floor(Math.log10(Math.abs(number)) / 3);
  let shortValue = number / Math.pow(1000, suffixNum);

  // Round to 2 decimal places
  shortValue = Math.round(shortValue * 100) / 100;

  // Remove trailing zeros and decimal point if necessary
  let formattedValue = shortValue.toFixed(2).replace(/\.?0+$/, '');

  // If the value is exactly an integer, don't show decimal places
  if (shortValue % 1 === 0) {
    formattedValue = shortValue.toFixed(0);
  }

  return formattedValue + ' ' + suffixes[suffixNum - 1];
}

export function calculatePercentageIncrease(currentCount, previousCount) {
  if (previousCount === 0) {
    // Avoid division by zero
    return {
      isIncreased: currentCount > 0,
      percentageIncrease: currentCount === 0 ? 0 : 100,
    };
  }

  const increase = currentCount - previousCount;
  const percentageIncrease = Math.round(
    (increase / Math.abs(previousCount)) * 100
  );

  return {
    isIncreased: increase > 0,
    percentageIncrease:
      percentageIncrease >= 0
        ? percentageIncrease
        : Math.abs(percentageIncrease),
  };
}

export function calculatePercentage(
  data,
  positiveProperty,
  negativeProperty,
  neutralProperty
) {
  if (!data) {
    return {
      Positive: 0,
      Negative: 0,
      Neutral: 0,
    };
  }

  // Set default property names if not provided
  positiveProperty = positiveProperty || 'sentiment_positive';
  negativeProperty = negativeProperty || 'sentiment_negative';
  neutralProperty = neutralProperty || 'sentiment_neutral';

  // Create the sentiment percentages object
  const sentimentPercentages = {
    Positive: data[positiveProperty],
    Negative: data[negativeProperty],
    Neutral: data[neutralProperty],
  };

  // Return the sentiment percentages
  return sentimentPercentages;
}

export function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000); // Adjust the range based on your needs
  const uniqueId = `id_${timestamp}_${random}`;
  return uniqueId;
}

export const getMediaCountDataUTIL = (data) => {
  const mediaTypesFromRes = data || [];
  const updatedMediaCountData = mediaTabData?.map((x) => {
    const mdDataForMap = mediaTypesFromRes.find((mdData) =>
      mdData?.key?.toLowerCase()?.includes(x.label?.toLowerCase())
    );
    if (mdDataForMap) {
      return {
        ...x,
        count: mdDataForMap?.doc_count,
      };
    } else if (x.value === 'totalArticles') {
      return {
        ...x,
        count: mediaTypesFromRes.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.doc_count;
        }, 0),
      };
    }
    return {
      ...x,
      count: 0,
    };
  });
  return updatedMediaCountData;
};

// Converts Capitailzed Sentence
export const capitalize = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  // Convert to lowercase first, then capitalize
  return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

// Gets selected social media types for the graph
export const getSelectedTypesForSocialGraph = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    const isSocialSelected = filters?.media_types?.some((x) =>
      social.includes(x)
    );
    if (isSocialSelected) {
      return {
        type: 'social',
        mediaTypes: filters?.media_types.filter((x) => social.includes(x)),
      };
    }

    const isTraditionalSelected = filters?.media_types?.some((x) =>
      traditional.includes(x)
    );
    if (isTraditionalSelected) {
      return {
        type: 'social',
        mediaTypes: social,
      };
    }
    return {
      type: 'social',
      mediaTypes: social,
    };
  }
  return {
    type: 'social',
    mediaTypes: social,
  };
};

// Gets selected traditional media types for the graph
export const getSelectedTypesForTraditionalGraph = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    const isTraditionalSelected = filters?.media_types?.some((x) =>
      traditional.includes(x)
    );

    if (isTraditionalSelected) {
      return {
        type: 'traditional',
        mediaTypes: filters?.media_types.filter((x) => traditional.includes(x)),
      };
    }

    const isSocialSelected = filters?.media_types?.some((x) =>
      social.includes(x)
    );

    if (isSocialSelected) {
      return {
        type: 'traditional',
        mediaTypes: traditional,
      };
    }

    return {
      type: 'traditional',
      mediaTypes: traditional,
    };
  }

  return {
    type: 'traditional',
    mediaTypes: traditional,
  };
};
